import { debugFactory } from '@iva/debugger';
import { LOG_COLOR_BG, LOG_COLOR_DEFAULT, SERVICE_NAME } from '../../constants';
const DEBUG_CONFIG = {
    name: SERVICE_NAME,
    bColor: LOG_COLOR_BG,
    fColor: LOG_COLOR_DEFAULT,
};
export const dbg = debugFactory(DEBUG_CONFIG);
/** Returns new url with "sznivadbg" and "sznivadbgsave" query params if they exist in the searchParams.
 * used for propagation iava dbg params from top level to crated iframe (its "src")
 * @param url - url (of frame) to append to.
 * @param searchParams - searchParams (from top level) to append, for example '?foo=bar&sznivadbg=DOT',
 * @returns new url with "sznivadbg" and "sznivadbgsave" query params, if they were in searchParams.
 */
export const copyDebugQuery = (url, searchParams) => {
    const queries = new URLSearchParams(searchParams);
    const sznivadbg = queries.get('sznivadbg');
    const sznivadbgsave = queries.get('sznivadbgsave');
    const parsed = new URL(url);
    if (sznivadbg) {
        parsed.searchParams.append('sznivadbg', sznivadbg);
    }
    if (sznivadbgsave) {
        parsed.searchParams.append('sznivadbgsave', sznivadbgsave);
    }
    return parsed.toString();
};
