import { getRandomString } from '../../utils/helpers/getRandomString';
import { SSP_URL } from '../../../constants';

/**
 * Appends iframe into page with meaningless src,
 * thus preventing iframe caching problems in Safari.
 * @param {HTMLElement} target - Element to append to.
 * @param {HTMLIframeElement} iframe - Iframe to be appended.
 * @param {String} src - Iframe src with desired content.
 */
export const appendWithCacheBusting = (target, iframe, src) => {
  iframe.src = `${SSP_URL.PROTOCOL}${SSP_URL.IFRAME_HOSTNAME}${SSP_URL.PATH_TO_EMPTY}?${getRandomString()}`;
  target.appendChild(iframe);
  try {
    // Using location.replace prevent new history records from being created
    iframe.contentWindow.location.replace(src);
  } catch (error) {
    iframe.src = src;
  }
};
