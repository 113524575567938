import { dbg } from '#Modules/utils/dbg';
// todo fix "any", when deps types are fixed (error_reporter)
export const getIS = () => window.sznIVA?.IS || null;
// temp fix (Partial identitites submited to IS
// -> inconsistence between window.login.current and settled LOGIN identity)
// will be fixed inside IS in future
const _validLoginAgainstNativeLoginCurrent = (login) => {
    return !!(login?.state &&
        window.login?.current?.state &&
        login.state === window.login?.current?.state &&
        // we validate that both UIDs are not set (NULL|UNDEFINED) or bot are set and equal
        // eslint-disable-next-line eqeqeq
        login?.uid == window.login?.current?.uid);
};
/**
 * wait for usable login identity
 */
export const waitForLogin = (timeoutMs = 5000) => {
    const getLoginIS = () => getIS()?.getIdentity('login');
    // check -> listen -> timeout
    return new Promise((resolve) => {
        let unsubscribe = null;
        let timeout = null;
        // optimize by direct badge listening
        const badgeListener = () => loginResolver('badge', false);
        // badge event
        window.addEventListener('badge', badgeListener);
        // universal resolver
        const loginResolver = (type, forceTerminate, loginData) => {
            const loginIS = loginData || getLoginIS();
            const loginValidated = !!(loginIS && _validLoginAgainstNativeLoginCurrent(loginIS));
            // terminate all (timeout, listener, subscriber, promise)
            const _terminate = (loginToResolve) => {
                dbg(`waitForLogin (${type}):`, loginToResolve, { timeoutMs, timeout, unsubscribe });
                unsubscribe?.();
                window.clearTimeout(timeout);
                window.removeEventListener('badge', badgeListener);
                resolve(loginToResolve);
                return true;
            };
            if (loginValidated) {
                return _terminate(loginIS);
            }
            else if (forceTerminate) {
                return _terminate(null);
            }
        };
        // immediate availability (if no timeout -> resolve immediately value from IS)
        if (loginResolver('immediate', !timeoutMs)) {
            return;
        }
        // timeout
        timeout = window.setTimeout(() => {
            if (loginResolver('timeout', true)) {
                return;
            }
        }, timeoutMs);
        // subsribe to IS -> wait for "login" to be present (fresh with "state" property)
        unsubscribe = getIS()?.subscribeIdentities(({ identities, changes }) => {
            dbg('waitForLogin (subscribing):', { identities, changes });
            if (!changes.includes('login')) {
                return;
            }
            const login = identities.login;
            if (login?.state === 'fresh') {
                if (loginResolver('subscribed', false, login.value)) {
                    return;
                }
            }
        }, true);
    });
};
