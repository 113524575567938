/**
 * @file SSP reklamní výdejový script
 *
 * @author Seznam Partner <ssp-nasazeni@firma.seznam.cz>
 *
 */
/* IVA Refresher */
import '@iva/refresher';
/* IAB SafeFrame support */
import './safeframe/sf.host';
/* IVA error handler */
import { startErrorHandling } from '@iva/error-reporter';
/* SSP itself */
import { constructSssp } from './modules/global/constructor';
startErrorHandling({
    endpoint: 'ssp-static',
    scriptName: 'ssp.js',
});
/**
 * @interface sssp
 * @desc Public object <code>sssp</code> exposing API methods for working with Seznam ads.
 */
const sssp = constructSssp();
export { sssp };
