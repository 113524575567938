import { dbg } from './dbg';
export const sendDataToExtension = (requestData, ads) => {
    // extension expects string values for element IDs
    const expectString = (data) => (typeof data === 'string' ? data : null);
    // all non-string IDs -> null
    const handleIdProperties = (collection) => collection.map(({ id, ...item }) => ({ ...item, id: expectString(id) }));
    // sanitize data before serialization
    const requestDataSanitized = {
        ...requestData,
        zones: handleIdProperties(requestData.zones),
    };
    const responseDataSanitized = handleIdProperties(ads);
    // send to extension
    try {
        const sspExtensionEvent = new CustomEvent('advertData', {
            detail: JSON.stringify({
                request: requestDataSanitized,
                response: responseDataSanitized,
            }),
        });
        window.dispatchEvent(sspExtensionEvent);
    }
    catch (e) {
        dbg('error', 'Failed to serialize data for SSP debug extensions');
        // no op
    }
};
export const sendSspAdsLoaded = (ad, data) => {
    const event = new CustomEvent('sspadsloaded', {
        detail: { ad, data },
    });
    window.dispatchEvent(event);
};
export const sendSspAdsChange = (data) => {
    const event = new CustomEvent('sspadschange', {
        detail: { data },
    });
    window.dispatchEvent(event);
};
export const emitDebugEvent = (name, data) => {
    dbg(`Emiting event: ${name}`, data);
    const event = new CustomEvent(name, {
        detail: data,
    });
    window.dispatchEvent(event);
};
export const sendAdbEvent = (iframe) => {
    var event = new CustomEvent('xiframe', {
        detail: {
            id: iframe.id,
        },
    });
    window.dispatchEvent(event);
};
export const sendPawVisibilityEvent = (name, data) => {
    var event = new CustomEvent(name, {
        detail: data,
    });
    window.dispatchEvent(event);
};
export const sendPageViewIdSetEvent = (data) => {
    const event = new CustomEvent('ssppvidset', {
        detail: { data },
    });
    window.dispatchEvent(event);
};
export const sendSspLoaded = () => {
    window.dispatchEvent(new CustomEvent('ssploaded'));
};
