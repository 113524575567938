import { getSessionData } from './sessionDetection';

/* eslint-disable camelcase */

export const getDopSourceData = () => {
  const sessionData = getSessionData();
  const dopParamsTransformations = {
    dop_req_id: 'req_id',
    dop_ab_variant: 'ab',
    dop_source_zone_name: 'zone_name',
    dop_id: 'id',
  };
  const dopSourceData = Object.keys(dopParamsTransformations).reduce((acc, current) => {
    const transformedDopKey = dopParamsTransformations[current];

    if (sessionData[current]) {
      acc[transformedDopKey] = sessionData[current];
    }
    return acc;
  }, {});
  return Object.keys(dopSourceData).length ? dopSourceData : null;
};
