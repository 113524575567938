import { getAdserverUrl } from './urls';
import { dbg } from './dbg';
import { getConfig } from '../config';
import { getFormattedUaDataHeaders } from './helpers/getFormattedUaDataHeaders';

const requestAds = (payload, pvIdStart, handlePageViewIdChange) =>
  new Promise(async (resolve, reject) => {
    const { method, pvId, protocol, server, version, path, test } = getConfig();

    /* Check for user-configured endpoint URL, or fall back to site-configured host */
    const url = getAdserverUrl({ protocol, server, version, path, test });

    if (!window.XMLHttpRequest) {
      dbg('error', 'No XHR available');
    }

    const xhr = new XMLHttpRequest();

    if (handlePageViewIdChange) {
      const pvIdChangeListner = (e) => {
        try {
          // request běží, ale v průběhu se nám změnilo pvId (např. rychlé přenavigování na SPA) - abortujeme XHR - nechceme takovou reklamu renderovat
          if (pvIdStart !== e.detail.data.pvId) {
            dbg('info', 'Aborting XHR - pvId changed');
            xhr.abort();
            resolve(false);
            return;
          }
        } catch {
          // no-op
        } finally {
          window.removeEventListener('ssppvidset', pvIdChangeListner);
        }
      };

      window.addEventListener('ssppvidset', pvIdChangeListner);
    }

    const errorCallback = () => {
      const errorName = `Error attempting XMLHttpRequest (error ${xhr.status}).`;
      dbg('error', errorName);
      reject({ errorName, errorCode: xhr.status });
    };

    xhr.onload = (e) => {
      if (handlePageViewIdChange && pvIdStart !== pvId) {
        // request OK, ale v průběhu se nám změnilo pvId (např. rychlé přenavigování na SPA) - nechceme takovou reklamu renderovat
        dbg('info', 'Ignoring XHR response - pvId changed');
        resolve(false);
        return;
      }
      if (!e.target || !e.target.responseText) {
        reject({
          errorName: e.currentTarget.statusText,
          errorCode: e.currentTarget.status,
        });
        return;
      }

      resolve(e.target.responseText);
    };

    xhr.onerror = errorCallback;
    xhr.onabort = errorCallback;
    xhr.ontimeout = errorCallback;

    xhr.open(method, url);
    xhr.withCredentials = true;
    xhr.setRequestHeader('Content-type', 'application/json');

    const uaDataHeaders = await getFormattedUaDataHeaders();

    if (uaDataHeaders) {
      for (const header in uaDataHeaders) {
        xhr.setRequestHeader(header, uaDataHeaders[header]);
      }
    }

    xhr.send(JSON.stringify(payload));
    dbg('sending data to adserver', payload);
  });

const requestCmp = (params) =>
  new Promise((resolve, reject) => {
    if (!params.webId && !params.zoneId) {
      return;
    }

    const { cmpServer } = getConfig();
    const query = params.webId ? `webId=${params.webId}` : `zoneId=${params.zoneId}`;
    const url = `${cmpServer}?${query}`;

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== 4) {
        return;
      }

      if (xhr.status === 200 && xhr.responseText) {
        resolve(xhr.responseText);
      } else if (xhr.status === 400) {
        reject('Bad request');
      } else {
        reject('Something wrong happened');
      }
    };

    xhr.open('GET', url);
    xhr.send();
  });

export { requestAds, requestCmp };
