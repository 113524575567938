/**
 * Hides and shows paw according to the event received from sf.host
 * @param {CustomEvent} event
 * @param {Object} event.detail
 * @param {String} event.type
 */
const handleEvent = ({ detail, type }) => {
  const { adContainer } = detail;
  const paw = adContainer.querySelector(`div.packa`);

  if (!paw) {
    return;
  }

  switch (type) {
    case 'showPaw':
      paw.style.visibility = 'visible';
      break;
    case 'hidePaw':
      paw.style.visibility = 'hidden';
      break;
  }
};

export const registerPawVisibilityListener = () => {
  window.addEventListener('hidePaw', handleEvent);
  window.addEventListener('showPaw', handleEvent);
};
