/**
 * Run callback inside try catch, is mostly intended for functions passed as
 * parameters to SSPs public functions, so that the caller knows about the error
 */
export const safelyExecuteCallback = (callback) => {
    try {
        return callback();
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};
