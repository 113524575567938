/**
 * Returns current timestamp that is accurate to hours
 * eg. for Tue Mar 17 2020 15:04:01 GMT+0100 (Středoevropský standardní čas)
 * this returns 202031715
 */
export const getTimestamp = () => {
    const date = new Date();
    return [date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours()].join('');
};
/** timestamp from start of lifecycle  */
let initTimestamp = null;
/**
 * Sets init timestamp
 */
export const setInitTimestamp = (timestamp = performance.now()) => {
    if (timestamp !== null && typeof timestamp !== 'number') {
        timestamp = null;
    }
    return (initTimestamp = timestamp);
};
/**
 * Gets time from init time till now
 */
export const getTimeFromInitTimestamp = () => (initTimestamp ? performance.now() - initTimestamp : null);
