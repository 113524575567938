import { getConfig } from '../config';
import { requestCmp } from '../utils/request';

/**
 * Resolves into CMP server response with CMP-related data for requested site.
 * Attempts to pass webid (if configured) or zoneId (if called from getAds).
 * @param zoneId {Number}
 * @returns {Promise}
 */
const getCmpInfo = (zoneId) =>
  new Promise((resolve, reject) => {
    const { webId } = getConfig();
    const requestData = { webId, zoneId };

    if (requestData.webId || requestData.zoneId) {
      requestCmp(requestData)
        .then((response) => {
          try {
            resolve(JSON.parse(response));
          } catch (error) {
            reject(error);
          }
        })
        .catch(reject);
    } else {
      reject('No webId or zoneId provided');
    }
  });

export { getCmpInfo };
