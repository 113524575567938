const secondLevelTlds = [
    'com.au',
    'net.au',
    'org.au',
    'edu.au',
    'gov.au',
    'co.at',
    'or.at',
    'ac.uk',
    'co.uk',
    'gov.uk',
    'ltd.uk',
    'co.nz',
];
/* For special cases when we're on actual TLD (hint: localhost) */
const _isTld = (hostname) => !hostname.includes('.');
const _isSecondLevelTld = (hostname) => secondLevelTlds.some((tld) => hostname.endsWith('.' + tld));
export const getDomainName = (hostname) => {
    if (_isTld(hostname)) {
        return hostname;
    }
    const sliceValue = _isSecondLevelTld(hostname) ? -3 : -2;
    return hostname.split('.').slice(sliceValue).join('.');
};
export const getCookieStorageDomainName = (hostname) => {
    const domainName = getDomainName(hostname);
    return _isTld(domainName) ? domainName : `.${domainName}`;
};
