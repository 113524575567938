/**
 * As DOT and ssp script itself are or can be loaded asynchronously, there is
 * a queue of callbacks stored before they can be executed. There are two queues
 * in ssp:
 *
 * 1. Internal, supported queue, stored in 'functionStack' array.
 * 2. Legacy, deprecated window.ssspQ array with monkey patched 'push' method
 * because of abuse by users.
 */

import { getDot } from '../impress/dotLoader';

const functionStack = [];

/**
 * Adds a function to a queue for later execution
 * @param {function} callback function to be executed,
 * @param  {...any} args arguments passed to callback
 * @returns {Promise} promise that resolves when callback is executed
 */
const addToQueue = (callback, ...args) => {
  if (getDot() === null) {
    return new Promise((resolve, reject) => {
      functionStack.push({ callback, args, resolve, reject });
    });
  } else if (typeof callback === 'function') {
    // Although Promise.resolve handles potentially rejected promise,
    // we must handle throwing synchronous functions.
    try {
      return Promise.resolve(callback(...args));
    } catch (e) {
      return Promise.reject(e);
    }
  }
};

/**
 * Executes all functions stored in the main queue
 */
const executeQueue = () =>
  functionStack.forEach((func) => {
    if (typeof func.callback === 'function') {
      // In case the callback returns a promise then just pass it on,
      // if it returns a normal value then wrap it in a promise.
      try {
        const returnValue = Promise.resolve(func.callback(...func.args));
        returnValue.then((ret) => func.resolve(ret)).catch((err) => func.reject(err));
      } catch (e) {
        func.reject(e);
      }
    }
  });

let isLegacyQueueMonkeyPatched = false;

/**
 * Monkey patch window.ssspQ's native 'push' method so it triggers queue execution
 * on top of adding callback to the array.
 *
 * @param {Function} executeQueueCallback function to run after adding function to array
 */
const monkeyPatchQueue = (executeQueueCallback) => {
  if (isLegacyQueueMonkeyPatched) {
    return;
  }

  if (!window.ssspQ) {
    window.ssspQ = [];
  }

  window.ssspQ.push = (userCallback) => {
    if (typeof userCallback !== 'function') {
      return;
    }
    window.ssspQ[window.ssspQ.length] = userCallback;
    executeQueueCallback();
  };
  isLegacyQueueMonkeyPatched = true;
};

/**
 * Execute legacy static queue.
 */
const executeStaticQueue = () => {
  monkeyPatchQueue(executeStaticQueue);
  while (window.ssspQ && window.ssspQ.length !== 0) {
    window.ssspQ.shift()();
  }
};

/**
 * Execute all the queues
 */
const executeQueues = () => {
  executeQueue();
  executeStaticQueue();
};

export { addToQueue, executeQueues };
