import { getAdElements } from './adTemplate';
import { getTargetElement } from './getTargetElement';
import { setSize, resize } from './sizing';
import { setContainerElement } from '../ads/adRepository';
import { sendPawVisibilityEvent } from '../utils/events';
import { PAW_RENDER_DELAY } from '../../constants';
import { revivePaw } from './getPawHTML';

/**
 * Prepares an ad space on page for rendering: adds an 'adFull' class
 * to container element and inserts ad template.
 * @param {Object} ad
 * @param {Object} position
 * @returns {Node} DOM element to insert ad content into
 */
export const prerenderAdToPage = (ad, position) => {
  const targetElement = getTargetElement(ad, position);
  const { adElement, adContent } = getAdElements(ad, position.options);

  if (!targetElement) {
    return null;
  }

  setSize(ad, position, targetElement);
  targetElement.classList.add('adFull');

  targetElement.appendChild(adElement);
  setContainerElement(ad, targetElement);

  const pawElement = targetElement.querySelector('div.packa');
  if (!ad.responsive && !(position && position.options && position.options.disableScaling)) {
    resize(ad, position, targetElement, adContent);
  }

  if (pawElement) {
    // not needed if paw isn't present
    const pawRenderDelay = (position.options && position.options.pawTimeout) || PAW_RENDER_DELAY;

    ad.pawTimeoutId = setTimeout(() => {
      sendPawVisibilityEvent('showPaw', { adContainer: targetElement });
    }, pawRenderDelay);
  }

  revivePaw(ad, targetElement, ['first', 'last']);

  return adContent;
};
