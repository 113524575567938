import { setConfig, getConfig } from '../config';
import getAds from '../ads/getAds';
import getAdsByTags from '../ads/getAdsByTags';
import { setPageViewId, getPageViewId } from '../utils/pageView';
import { sessionExists } from '../session/sessionDetection';
import { setSessionCookie } from '../session/sessionCookies';
import writeAd from '../rendering/writeAd';
import served from '../impress/served';
import processAdData from '../ads/processAdData';
import { cmpInit } from '../cmp/cmpLoader';
import { getDot } from '../impress/dotLoader';
import { setCustomPath } from '../customs';
import { getRenderedAds, updatePositionId, extendVisibilityTracking } from '../ads/adRepository';
import { getPawHTML } from '../rendering/getPawHTML';
import { appendPawToElement } from '../rendering/appendPawToElement';
import { appendCoopIconToElement } from '../rendering/appendCoopIconToElement';
import { isStandAlone } from '../config/globalFlags';
import { getVastData } from '../utils/vastData';
import { addToQueue } from '../utils/functionStack';
import init from '../init';
export default () => {
    const sssp = {
        /**
         * Main configuration method for setting config
         */
        config: (config) => {
            return addToQueue(() => {
                setConfig(config);
                if (!isStandAlone()) {
                    cmpInit(0);
                }
                sssp.conf = getConfig();
            });
        },
        /**
         * [deprecated] Object representing current configuration. Read-only.
         */
        conf: getConfig(),
        /**
         * Main method for calling and displaying ads. Allows requesting and displaying
         * a single zone (single object as the first parameter) or multiple zones by
         * passing an array of zone objects.
         */
        getAds: (positions, opt) => addToQueue(getAds, positions, opt),
        /**
         * A simpler method for requesting and displaying ads on the page. It doesn't
         * require to pass an array or object with zone configuration, instead it scans
         * the DOM for HTML elements with "data-szn-ssp-ad" attributes and creates the
         * object pased to getAds method.
         */
        getAdsByTags: () => addToQueue(getAdsByTags),
        /**
         * Inserts ad to page according to parameters passed.
         */
        writeAd,
        /**
         * Attempts to hit each provided URL in array in order to count metrics (miss,
         * impress, visible)
         */
        served,
        /**
         * Retrieve page view ID from config (web is Single-Page Application (SPA))
         */
        getPageViewId,
        /**
         * Set page view ID. If provided, pageview ID will be set to the value of
         * the argument. If not, the script will generate one (usually the desired
         * behavior).
         */
        setPageViewId: (pageViewId, resetVisibilityElements) => addToQueue(setPageViewId, pageViewId, resetVisibilityElements),
        /**
         * Detects an active Seznam partner session. Intended for partner websites
         * to check for traffic generated by Seznam and display ads according to the
         * traffic source.
         */
        displaySeznamAds: sessionExists,
        /**
         * Detects if a session cookie was set for a Seznam partner website
         *
         */
        existSessionCookie: sessionExists,
        /**
         * Set session cookie to mark valid session to continue serving Seznam ads
         */
        setSessionCookie: (media) => setSessionCookie({ media }),
        /**
         * Renders an ad or invokes a callback.
         */
        processAdData: (ad, data) => addToQueue(processAdData, ad, data),
        /**
         * Gets info about rendered positions/ads (= content of positionsStore). Watcher for future updates can be registered.
         */
        getRenderedAds,
        /**
         * Manually initialize SSP. Use only when `window._sspDoNotInit` has been set to
         * a truthy value before running ssp.js.
         */
        init,
        /**
         * Private method for debugging and testing purposes only
         * returns SSP's current DOT instance
         */
        _getDotInstance: getDot,
        /**
         * Updating element / container ID according to zoneId (if adRepository[item].ad.id = falsy) in adRepository (affects all positions satisfying condition)
         */
        updateAdContainerId: updatePositionId,
        /**
         * Sets custom resources paths for debugging and testing purposes only
         */
        _setCustomPath: setCustomPath,
        /**
         * Returns pawHTML for all DSP types except Sklik, OGAR & ONEGAR
         */
        getPawHTML,
        /**
         * Appends pawHTML to an element
         */
        appendPawToElement,
        /**
         * Appends coop icon to an element
         */
        appendCoopIconToElement,
        /**
         * Add positions from callback ad into positionsStore for visibility measurement
         */
        extendVisibilityTracking,
        /**
         * Clear sklikData from window object
         */
        clearData: () => {
            try {
                if (!delete window.sklikData) {
                    window.sklikData = null;
                }
            }
            catch (e) {
                window.sklikData = null;
            }
        },
        /**
         * Geet VAST related data (tags, ...) from "anywhere".
         * Returns Promise (syncing from top) -> works in top level and also embeds.
         */
        getVastData: (zoneIds) => addToQueue(() => getVastData(zoneIds)),
    };
    return sssp;
};
