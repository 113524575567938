import { getCmpInfo } from './cmpServerApi';
import { inBlacklist, inChunksWhitelist } from './whitelist';
import { setPaws } from './externalPaws';
import { copyCookieFromGlobalStorage } from './sync';
import { isAllowed } from './browserSniffing';
import { getCustoms } from '../customs';
import { getCookieStorageDomainName } from '../utils/helpers/getDomainName';
import DOT from './DOT';
import { getConfig, _writeConfig } from '../config';
import { getLatestConsent } from './consentCookie';
import { CONSENT } from '@sklik/cmp2-common';
import { setCookie, removeCookie } from '../utils/cookies';
import { doNotLoadCMP } from '../config/globalFlags';

let cmpInitialized = false;
const cookieStorageDomainName = getCookieStorageDomainName(location.hostname);
const Type = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};

export const getBaseUrl = () => {
  const custom = getCustoms('cmp');
  const urls = {
    prod: 'https://h.seznam.cz/js/cmp2',
    dev: 'https://h.seznam.dev.dszn.cz/js/cmp2',
    test: 'https://h.seznam.test.dszn.cz/js/cmp2',
  };

  if (custom) {
    // user has specified URL for debugging
    return custom;
  }

  const { currentScript } = getConfig();

  if (!currentScript) {
    // cannot read currentScript (maybe IE)
    // fallback to production
    return urls.prod;
  }

  const hostname = new URL(currentScript.src).hostname;

  if (hostname.includes('seznam.dev.dszn.cz')) {
    return urls.dev;
  }
  if (hostname.includes('seznam.test.dszn.cz')) {
    return urls.test;
  }

  return urls.prod;
};

export const getCmpScriptUrl = (type, hostname) => {
  const script = type === Type.EXTERNAL ? 'scmp-external.js' : 'scmp.js';
  const url = new URL(`${getBaseUrl()}/${script}`);
  if (inChunksWhitelist(hostname)) {
    url.searchParams.set('chunks', '1');
  }
  return url.href;
};

const getCmpStubUrl = () => `${getBaseUrl()}/cmp_stub.js`;

/**
 * Insert CMP script into the DOM
 * @param {('internal'|'external')} [type]
 */
const cmpInsert = (type = Type.INTERNAL) => {
  if (type === Type.INTERNAL && !isAllowed) {
    // We are not in allowed browser or this is an Apple device, do not load CMP
    return;
  }

  if (window.scmpInserted) {
    // CMP already inserted (e.g. config with webId and getAds are called together)
    return;
  }

  const cmpStub = document.createElement('script');
  cmpStub.src = getCmpStubUrl();
  document.head.appendChild(cmpStub);

  const cmpScript = document.createElement('script');
  cmpScript.src = getCmpScriptUrl(type, window.location.hostname);
  document.head.appendChild(cmpScript);

  window.scmpInserted = true;
};

/**
 * Initialize CMP loading
 * @param {Number} zoneId
 */
export const cmpInit = async (zoneId) => {
  DOT.config();

  if (doNotLoadCMP() || cmpInitialized) {
    return;
  }

  try {
    if (window.scmp || window.parent.scmp) {
      // CMP already inserted directly
      cmpInitialized = true;
      return;
    }
  } catch (error) {
    // ssp in cross domain iframe, ignore and continue.
  }

  try {
    const cmpData = await getCmpInfo(zoneId);
    const { internalPartner, showDialog, startDate } = cmpData;

    _writeConfig({ cmpData });
    cmpInitialized = true;
    const cmpConfigEvent = new CustomEvent('cmpdatareceived', { detail: { ...cmpData } });
    window.dispatchEvent(cmpConfigEvent);

    // Pass cmp data to cmp dialog by global variable
    // eslint-disable-next-line camelcase
    window.scmp_sspServerData = cmpData;

    if (!showDialog) {
      // CMP is disabled for this site
      return;
    }

    if (internalPartner) {
      if (!inBlacklist(window.location.hostname)) {
        cmpInsert(Type.INTERNAL);
      }
      return;
    }

    if (startDate) {
      // We're on an external partner site with CMP enabled, load CMP
      cmpInsert(Type.EXTERNAL);
      setPaws(true);
      return;
    }

    /** We're on an external partner site with CMP disabled,
     * use whatever cookie we have available */

    const localConsentCookie = await getLatestConsent();

    if (!localConsentCookie) {
      /** No local consent cookie found. Attempt to use cookie stored in
       * global storage (if exists) */
      copyCookieFromGlobalStorage();
      return;
    }

    /* Cookie found in local domain - resave for all subdomains */
    /* Resave only for "our" cookie names */
    if (CONSENT.CONSENT_COOKIE_NAME === localConsentCookie.name) {
      removeCookie({ name: localConsentCookie.name });
      setCookie({
        name: localConsentCookie.name,
        value: localConsentCookie.consentString,
        domain: cookieStorageDomainName,
        expires: [365, 'days'],
      });
    }
  } catch (error) {
    // Request to CMP server failed. We'll try next time.
  }
};
