import { sanitizeStrings } from '#Modules/utils/collocation';
import { getJson } from '#Modules/utils/helpers/getJson';
/**
 * Parse JSON (from a script tag content)
 */
const parseLD = (scriptTag) => getJson(scriptTag.innerHTML);
/**
 * Get all scripts of type "ld+json" and parse them
 */
export const getParsedLDs = () => {
    const allLds = document.querySelectorAll('script[type="application/ld+json"]');
    return [...allLds].map((ld) => parseLD(ld)).filter(Boolean);
};
/**
 * Get all keywords from all parsed LDs
 */
export const getKeywords = () => {
    const lds = getParsedLDs();
    const keywords = lds.flatMap((ld) => (ld.keywords ? ld.keywords.split(',') : []));
    return sanitizeStrings(keywords);
};
