import { dbg } from '../utils/dbg';

/**
 * Podle rozmeru, ktery presahuje velikost containeru, provede resize reklamy
 * @param {Object} ad - reklama z ssp
 * @param {Object} data - definice pozice
 * @param {Node} container - prvek ve strance (hlavni element)
 * @param {Node} f - prvek ve strance (nas s inner)
 */
const resize = function (ad, data, container, f) {
  dbg('info', '### resizing ###');
  if (container.offsetWidth < ad.width || container.offsetHeight < ad.height) {
    var wRatio = container.offsetWidth / ad.width;
    var hRatio = container.offsetHeight / ad.height;
    var r = Math.min(wRatio, hRatio);

    const dataset = { h: container.offsetHeight, w: container.offsetWidth, hR: f.offsetHeight, wR: f.offsetWidth };

    if (!wRatio) {
      r = hRatio;
    }
    if (!hRatio) {
      r = wRatio;
    }
    f.style.transformOrigin = 'top left';
    f.style.transform = 'scale(' + r + ')';
    container.style.height = Math.round(ad.height * r) + 'px'; // pokud resizujeme ... upravime i vysku containeru
    container.dataset.sspScaled = 'yes';
    dataset.hScaled = container.offsetHeight;
    dataset.wScaled = container.offsetWidth;
    dataset.scale = r;
    container.dataset.sspScaledMetadata = JSON.stringify(dataset);
  }
};

const setSize = function (ad, data, container) {
  if (
    !container.offsetHeight &&
    (!ad.responsive || (ad.type === 'iframe_url' && ['SKLIK', 'ONEGAR'].includes(ad.dsp)))
  ) {
    container.style.height = ad.height + 'px';
  }

  if (!ad.responsive && (container.offsetWidth || container.offsetWidth !== ad.width)) {
    if (ad.width < document.documentElement.scrollWidth) {
      container.style.width = ad.width + 'px';
      container.style.maxWidth = '100%';
    }
  }

  // nejlepsi reseni pro branding pres pole elements
  if (data.bestElement && !container.offsetWidth) {
    container.style.width = ad.width + 'px';
  }
};

export { resize, setSize };
