/**
 * Vybere z pole elements odpovídající Node; musí mít stejnou nebo nejbližší větší velikost
 * @param ad {Object} - reklama z sssp
 * @param elements {Array}
 **/
const getBestElement = (ad, elements) => {
  const defaultValues = {
    area: Infinity,
    bestElement: null,
  };

  const calculatedValues = elements.reduce((acc, currentElement) => {
    const { width, height } = currentElement;
    if ((width || ad.width) < ad.width || (height || ad.height) < ad.height) {
      return acc;
    }

    const area = (width || ad.width) * (height || ad.height);
    if (area < acc.area) {
      return {
        area,
        bestElement: currentElement,
      };
    } else {
      return acc;
    }
  }, defaultValues);

  return calculatedValues.bestElement;
};

export const getTargetElement = (ad, position) => {
  if (!position.elements && position.id) {
    return typeof position.id === 'string' ? document.getElementById(position.id) : position.id;
  } else if (position.elements) {
    const bestElement = getBestElement(ad, position.elements);
    return typeof bestElement.id === 'string' ? document.getElementById(bestElement.id) : bestElement.id;
  }
};
