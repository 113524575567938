import { prerenderAdToPage } from '../prerenderAdToPage';
import { createIframe } from '../createElements';
import { getConfig } from '../../config';
import { sendAdbEvent } from '../../utils/events';
import { isThisSafari } from '../../cmp/browserSniffing';
import { appendWithCacheBusting } from './cacheBuster';
import { dbg, copyDebugQuery } from '../../utils/dbg';
import { getJson } from '../../utils/helpers/getJson';

/**
 * Wrapper function for currying callback functions and removing event listeners
 * @param {Function} callback
 * @param {...any} args
 * @returns {Function} event handler
 */
const getEventHandler = (callback, ...args) => {
  const eventHandler = (event) => {
    event.target.removeEventListener(event.type, eventHandler);
    callback(...args);
  };

  return eventHandler;
};

/**
 * Run when iframe content has loaded
 * @param {Object} ad
 * @param {Object} position
 * @param {Node} iframe DOM node
 * @param {Function} callback to run when iframe is loaded
 */
const onload = (ad, position, iframe, callback) => {
  const { protocol, iframeStaticServer } = getConfig();
  let targetOrigin = protocol + iframeStaticServer;

  if (ad.type === 'iframe_url') {
    const urlParts = ad.data.match(/((?:http[s]?)?:\/{2}[^\/\s]+)(.*)?/);
    targetOrigin = urlParts[1];
  }

  new $sf.SF(iframe, ad, position, targetOrigin);

  if (ad.type === 'iframe') {
    const messageContent = JSON.stringify({ ...ad, sznssp: true, method: 'requestRender' });
    iframe.contentWindow?.postMessage(messageContent, targetOrigin);

    // Set data attribute to rendered iframe (type === 'iframe').
    // Useful for running tests only after an ad had been fully injected into the iframe.
    window.addEventListener('message', (event) => {
      const message = getJson(event.data);
      if (message && message.event === 'IFRAME_AD_RENDERED' && message.impressId === ad.impressId) {
        iframe.dataset.cyRendered = 'true';
      }
    });
  }

  if (ad.type === 'iframe_url') {
    if (ad.adInfoUrl) {
      iframe.contentWindow?.postMessage({ sznPawUrl: ad.adInfoUrl }, targetOrigin);
    }
    const messageContent = JSON.stringify({
      sznssp: true,
      ad: { pageViewId: ad.pageViewId, requestId: ad.requestId },
      method: 'setInfo',
    });
    iframe.contentWindow?.postMessage(messageContent, targetOrigin);
  }

  sendAdbEvent(iframe);

  /* Hack for Cypress so it only accesses iframes when they're ready */
  if (window.Cypress) {
    iframe.setAttribute('data-cy-loaded', 'true');
  }

  if (typeof callback === 'function') {
    return callback();
  }
};

const onerror = (iframe) => {
  sendAdbEvent(iframe);
};

/**
 * Renders an ad of type: iframe || iframe_url
 * @param {Object} ad
 * @param {Object} position
 * @param {Function} callback
 */
const writeIframe = (ad, position, callback) => {
  const adContent = prerenderAdToPage(ad, position);
  const iframe = createIframe(ad);
  let src = null;
  const { pvId } = getConfig();

  const onloadCallback = getEventHandler(
    onload,
    {
      ...ad,
      pageViewId: pvId,
    },
    position,
    iframe,
    callback
  );
  const onerrorCallback = getEventHandler(onerror, iframe);
  iframe.addEventListener('load', onloadCallback);
  iframe.addEventListener('error', onerrorCallback);

  if (ad.type === 'iframe_url') {
    src = ad.data;
  } else if (ad.type === 'iframe') {
    const { protocol, iframeStaticServer, iframeUrl } = getConfig();
    src = `${protocol}${iframeStaticServer}${iframeUrl}?url=${encodeURIComponent(document.location.href)}`;
    if (dbg()) {
      src = copyDebugQuery(src, window.location.search);
    }
  }

  if (isThisSafari()) {
    appendWithCacheBusting(adContent, iframe, src);
  } else {
    iframe.src = src;
    adContent.appendChild(iframe);
  }
};

export default writeIframe;
