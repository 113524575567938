import { dbg } from './dbg';
import { _writeConfig, getConfig } from '../config';
import { getDot } from '../impress/dotLoader';
import { getRandomString } from './helpers/getRandomString';
import { getTimeFromInitTimestamp, setInitTimestamp } from './timestamp';
import { updateSession } from '../session/sessionDetection';
import { getDomainName } from './helpers/getDomainName';
import getLocation from './helpers/getLocation';
import { sendSpentTimeData } from '../session/spentTime';
import { setCids } from '../ads/cids';
import { resetConsentMetadata } from '../cmp/consentCookie';
import { DOT_FIRST_IMPRESS_EVENT, IMPRESS_TIMEOUT } from '../../constants';
import { sendPageViewIdSetEvent } from './events';
import { adLoadWaitForImpress } from '../config/globalFlags';
import { clearElmsIntersections } from '../impress/visibilityMeasure';

let pageViewId = null;

/**
 * Sends "adload" hit
 * @param {DOT} dot instance
 * @param {Object} hitData webhit data
 */
const sendAdLoad = (dot, hitData) => dot.hit('adload', { d: hitData });

/**
 * Waits for "impress" hit, and then sends "adload" hit
 * @param {DOT} dot instance
 * @param {Object} hitData webhit data
 */
const waitForFirstImpress = (dot, hitData) => {
  let timer = null;

  const done = () => {
    window.removeEventListener(DOT_FIRST_IMPRESS_EVENT, done);
    clearTimeout(timer);
    timer = null;
    sendAdLoad(dot, hitData);
  };

  timer = setTimeout(() => {
    window.removeEventListener(DOT_FIRST_IMPRESS_EVENT, done);
    sendAdLoad(dot, hitData);
  }, IMPRESS_TIMEOUT);

  window.addEventListener(DOT_FIRST_IMPRESS_EVENT, done);
};

/**
 * Uchovávání prev hosta při přechodu mezi stránkami na SPA webu
 */
let lastPageUrl = document.referrer || '';
export const setLastPageHost = (url) => (lastPageUrl = url);
export const getLastPageHost = () => lastPageUrl;

/**
 * Nastavuje id pro stranku, vola se pri vlozeni ssp scriptu do stranky a nebo
 * v SPA aplikacich rucne, kdyz je potreba
 */
const setPageViewId = (newPageViewId = null, resetVisibilityElements = false) => {
  const pvId = newPageViewId || getRandomString(50);

  const updatedConfig = { pvId };

  // update ssp session a site při přechodu mezi stránkami na SPA webu
  if (getDomainName(getLastPageHost()) === getDomainName(document.location.hostname)) {
    setCids([]);
    updateSession();
    updatedConfig.site = getLocation();
  }

  _writeConfig(updatedConfig);

  sendPageViewIdSetEvent({ pvId });

  const dot = getDot();
  dbg('info', '#### setting pageViewId:', pvId);

  if (dot) {
    const hitData = { adId: pvId };

    // expand hitData with time from init
    const timeFromInit = getTimeFromInitTimestamp();
    if (timeFromInit) {
      hitData.timeFromInit = timeFromInit;
      setInitTimestamp(null);
    }

    if (adLoadWaitForImpress() && !window.DOT.impressBeforeAdloadSent) {
      waitForFirstImpress(dot, hitData);
    } else {
      sendAdLoad(dot, hitData);
    }
  }
  setLastPageHost(document.location.hostname);

  setTimeout(() => {
    // Because of the single page apps, we need to [re]init spenttime here
    sendSpentTimeData();
  });

  resetConsentMetadata();

  if (resetVisibilityElements) {
    clearElmsIntersections();
  }
};

const getPageViewId = () => getConfig().pvId;

const pageViewIdChanged = (newPageViewId) => {
  if (newPageViewId !== pageViewId) {
    pageViewId = newPageViewId;
    return true;
  } else {
    return false;
  }
};

export { setPageViewId, getPageViewId, pageViewIdChanged };
