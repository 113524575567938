/**
 * Extracts GET parameters from URL as key: value structured object
 */
export const getUrlParams = () => {
    try {
        const urlObj = new URL(window.location.href);
        const searchParams = urlObj.searchParams.entries();
        return Array.from(searchParams).reduce((acc, current) => {
            const [key, value] = current;
            acc[key] = value;
            return acc;
        }, {});
    }
    catch {
        return {};
    }
};
/**
 * Extracts value for a single querystring-style hash parameter
 * For parameter without value, even without equal sign, returns empty string.
 * For non existing parameter, returns null.
 */
export const getParameterFromURLHash = (name, hash = window.location.hash) => {
    const result = new URLSearchParams(hash.substring(1)).get(name);
    if (!result) {
        return result;
    }
    return decodeURIComponent(result.replace(/\+/g, ' '));
};
/**
 * Attempts to extract value of a querystring parameter from either regular URL searchParams or from URL hash
 */
export const getParamFromQuerystringOrHash = (name, url = window.location.href) => {
    try {
        const parsedUrl = new URL(url);
        return parsedUrl.searchParams.get(name) || getParameterFromURLHash(name, parsedUrl.hash);
    }
    catch {
        return null;
    }
};
