import { dbg } from '../utils/dbg';
import getAds from './getAds';

/**
 * A simpler method for requesting and displaying ads on the page. It doesn't
 * require to pass an array or object with zone configuration, instead it scans
 * the DOM for HTML elements with "data-szn-ssp-ad" attributes and creates the
 * object pased to getAds method.
 */
const getAdsByTags = function () {
  var divs = document.querySelectorAll('div[data-szn-ssp-ad]');
  var data = [];

  for (var i = 0; i < divs.length; i++) {
    try {
      var o = JSON.parse(divs[i].getAttribute('data-szn-ssp-ad'));
      o.id = divs[i];
      data.push(o);
    } catch (e) {
      dbg('error', e);
    }
  }

  getAds(data);
};

export default getAdsByTags;
