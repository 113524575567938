import { getConfig, _writeConfig } from '../config';
import { getDomainName } from '../utils/helpers/getDomainName';
import { getParamFromQuerystringOrHash } from '../utils/helpers/getUrlParams';
import { getLastPageHost } from '../utils/pageView';
import { setSessionCookie, removeSessionCookie, getDataFromSessionCookie } from './sessionCookies';

export const getDopParametersFromUrl = () => {
  const { dopUrlParameters } = getConfig();
  return dopUrlParameters.reduce((acc, param) => {
    const paramValue = getParamFromQuerystringOrHash(param);
    if (paramValue) {
      acc[param] = paramValue;
    }
    return acc;
  }, {});
};

/**
 * Is this a user navigation event on publisher's website?
 * @returns {Boolean}
 */
const referrerMatchesService = () => {
  try {
    const ref = document.referrer ? new URL(document.referrer) : null;
    const refDomain = ref ? getDomainName(ref.hostname) : null;
    const prevHostDomain = getDomainName(getLastPageHost());
    const currentDomain = getDomainName(document.location.hostname);
    return refDomain === currentDomain || prevHostDomain === currentDomain;
  } catch (error) {
    return false;
  }
};

/**
 * Is utm_source parameter in URL set to correct value?
 * Is utm_medium present in URL?
 * @returns {Boolean}
 */
const landingPageParamsValid = () => {
  return (
    !!getParamFromQuerystringOrHash('utm_medium') && getParamFromQuerystringOrHash('utm_source') === 'www.seznam.cz'
  );
};

/**
 * The referrer must be homepage or empty string
 * @returns {Boolean}
 */
export const isFirstPageview = () => {
  try {
    const ref = new URL(document.referrer);
    return ref.hostname === 'www.seznam.cz' && landingPageParamsValid();
  } catch (error) {
    return document.referrer === '';
  }
};

/**
 * Find valid source medium in query params or hash.
 * Filter it through allowed sources.
 * @param {String} medium
 * @returns {String|null}
 */
const findValidSourceMedium = () => {
  const medium = getParamFromQuerystringOrHash('utm_medium');
  const { allowedSources } = getConfig();
  return medium in allowedSources ? allowedSources[medium] : null;
};

/**
 * Extract session data from URL and override with data from cookie if possible
 * @returns {Object}
 */
export const getSessionData = () => {
  // Read cookie data only while navigating the website
  const cookieData = referrerMatchesService() && getDataFromSessionCookie();

  // Read "media" from URL only on landing page from feed
  const media = getParamFromQuerystringOrHash('utm_source') === 'www.seznam.cz' ? findValidSourceMedium() : null;

  return {
    media,
    // Read "dop" parameters from URL when available and referrer is homepage or empty
    ...(isFirstPageview() ? getDopParametersFromUrl() : {}),
    // Override with cookie data when available
    ...cookieData,
  };
};

/**
 * The session is going to be either refreshed to T+30 minutes or deleted.
 * Runs on each page load.
 */
export const updateSession = () => {
  const sessionData = getSessionData();
  const source = sessionData.media;
  if (source) {
    setSessionCookie(sessionData);
    _writeConfig({ source });
  } else {
    removeSessionCookie();
  }
};

/**
 * Do we have a session?
 * @returns {Boolean}
 */
export const sessionExists = () => getSessionData().media !== null;
