import { getScriptParams } from '../utils/scriptParams';
import { getCustoms } from '../customs';
import { loadScript } from '../utils/loadScript';
import { INTERNAL_SERVICES } from '../../constants';
/* global DOT */

const { dotUrl } = getScriptParams();

let dotInstance = null;
let dotTimer = null;

const dotConfig = {
  service: 'sklikp',
  load: false,
  mousedown: false,
};

const dotFallback = {
  hit: () => null,
  getNewInstance: {},
  _cfg: {},
  fallback: true,
};

const dotSource = dotUrl || getCustoms('dot') || '//h.seznam.cz/js/dot-small.js';

const configureDot = () => {
  if (window.DOT && window.DOT.getNewInstance) {
    dotInstance = DOT.getNewInstance();
    dotInstance.cfg(dotConfig);
    window.dispatchEvent(new CustomEvent('ssspdotconfigured'));
  } else {
    throw new Error('### DOT loaded but no DOT instance in window ###');
  }

  window.clearTimeout(dotTimer);
};
const waitingForLoadDot = () =>
  new Promise((resolve, reject) => {
    dotTimer = window.setTimeout(() => {
      if (window.DOT) {
        // nejakym jinym zpusobem se mohl dostat do do stranky .. vyuzijeme to
        if (!window.DOT.isConfigured()) {
          try {
            configureDot();
            resolve();
          } catch (err) {
            reject(err);
          }
        }
        resolve();
      }
      reject('no DOT');
    }, 5000);
  });

const loadDot = () =>
  new Promise((resolve, reject) => {
    const configure = () => {
      try {
        configureDot();
      } catch (err) {
        reject(err);
      }
    };

    if (!window.DOT) {
      if (!INTERNAL_SERVICES.includes(window.location.hostname)) {
        return loadScript(dotSource)
          .then(() => {
            window.dispatchEvent(new CustomEvent('ssspdotloaded'));
            configure();
            return resolve();
          })
          .catch((error) => {
            dotInstance = dotFallback;
            return reject(error);
          });
      }
      return waitingForLoadDot()
        .then(() => {
          dotInstance = window.DOT;
          resolve();
        })
        .catch((error) => {
          dotInstance = dotFallback;
          return reject(error);
        });
    }

    if (DOT.isConfigured() === false) {
      dotTimer = window.setTimeout(() => {
        /* DOT exists, but was not configured by the service. Attempting to set up our own instance. */
        if (window.DOT.isConfigured && !DOT.isConfigured()) {
          configure();
        } else {
          dotInstance = DOT;
        }
        resolve();
      }, 2000);

      return window.addEventListener(DOT.setAfterCfgEvent(), () => {
        window.clearTimeout(dotTimer);
        dotInstance = DOT;
        resolve();
      });
    }

    dotInstance = DOT;
    resolve();
  });

const setDot = () => {
  dotInstance = DOT;
};

const getDot = () => dotInstance;

export { loadDot, getDot, setDot };
