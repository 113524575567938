import { getConfig } from '../config';
import { getDot } from '../impress/dotLoader';

export const hitAction = (action, zoneId) => {
  const { dotMeasureZonesIds } = getConfig();
  const DOT = getDot();
  if (dotMeasureZonesIds.includes(zoneId)) {
    DOT.hit('event', {
      d: {
        action,
        zoneId,
      },
    });
  }
};
