import { getDot } from '../impress/dotLoader';
import { dbg } from '../utils/dbg';
import { isTopWindow } from '../utils/topWindow';
import { getDopSourceData } from './dopSource';

const blacklist = [
  'elektrina.cz',
  'esjnews.com',
  'gamingsite.cz',
  'jakbytfit.cz',
  'studentpoint.cz',
  'svetandroida.cz',
  'trailhunter.cz',
  'ulekare.cz',
  'ventil.vehiklo.cz',
  'vitalnisenior.cz',
];

export const sendSpentTimeData = () => {
  const host = document.location.hostname.replace('www.', '').toLowerCase();
  const normalizedBlacklist = blacklist.map((domain) => domain.toLowerCase());
  if (normalizedBlacklist.includes(host)) {
    return;
  }

  if (!isTopWindow(window)) {
    return;
  }

  const dopSourceData = getDopSourceData();

  const DOT = getDot();
  if (!DOT) {
    dbg('error', 'Unable to get DOT instance');
  } else if (!DOT.spenttime) {
    dbg('warn', 'Current DOT instance lacking spenttime module. Unable to send spenttime hits.');
  } else {
    // `initiator: ssp` is a temporary solution for migrating internal services to SSP initiated Spenttime
    /* eslint-disable-next-line camelcase */
    DOT.spenttime({ d: dopSourceData ? { dop_source: dopSourceData } : {}, initiator: 'ssp' });
  }
};
