import { getConfig } from '../config';
import { sendHit } from '../utils/sendHit';

/**
 * Formování hitError na základě impress/miss url
 * @param  urlList (Array) - pole impress URL
 */
const hitError = function (urlList) {
  const { server } = getConfig();
  const regexp = new RegExp(
    server.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') +
      `\/[a-zA-Z0-9]+?\/(impress|miss)[\?]{1}r=[\-a-zA-Z0-9]{36}&z=[^\? &]+&i=[^\? &]+&`
  );
  const urlImpress = urlList.find((url) => regexp.test(url));
  if (!urlImpress) {
    return;
  }

  const urlParams = new URL(urlImpress).searchParams;
  const hitErrorParams = {
    r: urlParams.get('r'),
    z: urlParams.get('z'),
    i: urlParams.get('i'),
    t: 'hitError',
  };

  sendHit(hitErrorParams);
};

export default hitError;
