import { dbg } from '../utils/dbg';

/**
 * @module sssp/customs
 * @desc Module for customs solutions in <code>window.localStorage.ssspCustomPaths</code>.
 */

const STORAGE_OBJECT_NAME = 'ssspCustomPaths';
const ALLOWED_KEYS = [
  'server', // server where ads will be served
  'staticServer', // ssp static server
  'iframeStaticServer', // ssp static server for iframe with reklama.html
  'cmpServer', // cmp server
  'cmp', // cmp script directory
  'dot', // dot-small.js url
  'adform', // adform_sync.html url
];

const isStorageSupported = (() => {
  const test = 'localStorageTest';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
})();

/**
 * Sets custom path of resource.
 * Can be called more times.
 *
 * @param {string} key one of allowed keys
 * @param {string} value
 */
export const setCustomPath = (key, value) => {
  if (!isStorageSupported || !ALLOWED_KEYS.includes(key)) {
    return;
  }

  const paths = localStorage.getItem(STORAGE_OBJECT_NAME);

  try {
    if (paths) {
      // parse data and delete local storage
      const data = JSON.parse(paths);
      localStorage.removeItem(STORAGE_OBJECT_NAME);

      // update and save
      data[key] = value;
      localStorage.setItem(STORAGE_OBJECT_NAME, JSON.stringify(data));
      dbg('info', '### custom paths - object updated.');
    } else {
      // create new object and save
      const newData = {};
      newData[key] = value;
      localStorage.setItem(STORAGE_OBJECT_NAME, JSON.stringify(newData));
      dbg('info', '### custom paths - new object created.');
    }
  } catch (e) {
    dbg('info', '### custom paths - data are invalid.');
  }
};

/**
 * Gets custom value from local storage if found. Otherwise returns null
 *
 * @param {string} key one of allowed keys
 * @returns {string|null}
 */
export const getCustoms = (key) => {
  if (!isStorageSupported || !ALLOWED_KEYS.includes(key)) {
    return null;
  }

  const paths = localStorage.getItem(STORAGE_OBJECT_NAME);

  if (!paths) {
    return null;
  }

  try {
    const data = JSON.parse(paths);

    if (data[key]) {
      dbg('info', '### custom paths - ' + key + ' is set to: ' + data[key]);
      return data[key];
    }
  } catch (e) {
    return null;
  }
  return null;
};
