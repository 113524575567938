import { dbg } from './dbg';
import { loadScript } from './loadScript';
import { omitWww } from './urls';
import { sendHit } from './sendHit';

export const addGamFlagsToPayload = (requestPayload) => {
  requestPayload.zones.forEach((zone) => {
    zone.options = { ...zone.options, gam: true };
  });
};

export const generateGamDimensionsFromMissAdData = (allowedDimensions, fallbackDimensions) => {
  if (Array.isArray(allowedDimensions)) {
    return allowedDimensions.map(({ width, height }) => [width, height]);
  } else {
    return fallbackDimensions;
  }
};

export const isBlacklisted = () => {
  const blacklist = ['iprima.cz', 'kurzy.cz', 'nakluky.cz', 'autosalon.tv', 'playzone.cz', 'aimatch.com'];
  const hostname = window.location.hostname.toLowerCase();

  return blacklist.includes(omitWww(hostname));
};

/**
 * For "empty" ads we can request GAM ad (rendered to specified new HTML element)
 */
export const gamFallback = (ad, data) => {
  if (!ad?.zoneId || !data?.options?.sspRendering || !data?.width || !data?.height || !data?.id) {
    dbg('info', 'GAM fallback - missing data');
    return;
  }

  if (isBlacklisted()) {
    dbg('info', 'GAM fallback - service blacklisted');
    return;
  }

  dbg('info', 'GAM fallback - starting');

  const elementId = 'div-gpt-ad-1677658328819-' + ad.zoneId;

  const dimensions = generateGamDimensionsFromMissAdData(ad.formats?.sizes, [[data.width, data.height]]);

  // load google script & config
  loadScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js')
    .then(() => {
      try {
        // prepare div for GAM rendering
        const gElement = document.createElement('div');
        gElement.setAttribute('id', elementId);

        // get "empty" ad element
        const emptyAdElement = document.getElementById(data.id);

        // append to dom (after "empty" element or at the end of parent)
        if (emptyAdElement?.parentElement && emptyAdElement?.nextSibling) {
          emptyAdElement.parentElement.insertBefore(gElement, emptyAdElement.nextSibling);
        } else if (emptyAdElement.parentElement) {
          emptyAdElement.parentElement.appendChild(gElement);
        } else {
          dbg('error', 'GAM fallback - no element to render');
          return;
        }

        // GAM config
        window.googletag = window.googletag || { cmd: [] };
        window.googletag.cmd.push(function () {
          window.googletag
            .defineSlot('/22819958440/ca-pub-2127466377868208-tag', dimensions, elementId)
            .addService(window.googletag.pubads());
          window.googletag.pubads().enableSingleRequest();
          window.googletag.enableServices();
        });
        dbg('info', 'GAM fallback - configured', dimensions, elementId);

        // render
        window.googletag.cmd.push(function () {
          dbg('info', 'GAM fallback - require display', dimensions, elementId);
          window.googletag.display(elementId);
        });

        // TEMPORARY log GAM calls
        const { requestId, zoneId, impressId } = ad;
        sendHit({ r: requestId, z: zoneId, i: impressId || 0, t: 'gam', v: 1 });
      } catch (e) {
        dbg('error', 'GAM fallback - ad rendering failed', e);
      }
    })
    .catch((e) => {
      dbg('error', 'GAM fallback - gpt.js loading failed', e);
    });
};
