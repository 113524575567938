import { getConfig } from '../config';
import { dbg } from '../utils/dbg';

/** Sets up a listener which listens for messages from the current window.
 * If the message is of type 'getUtmSource', it sends a message back to the
 * sender with the utm_source from config.
 */
export const setUpUtmMessageListener = () => {
  window.addEventListener('message', (e) => {
    if (e.data.type === 'getUtmSource') {
      dbg('info', 'received getUtmSource message from iframe');
      const source = getConfig().source;
      e.source.postMessage({ type: 'utmSource', source }, '*');
    }
  });
};

// Returns the master window, which is the top window if there is no Cypress
// or the parent window if there is Cypress (Here we presume that there will be
// no SSP in iframe which is also in an iframe in tests).
const getLeaderWindow = () => {
  try {
    if (window.top.Cypress) {
      return window.parent;
    }
  } catch (e) {
    // noop
  }
  return window.top;
};

/** Tries to get utm_source from SSP in top window.
 * Returns a promise that resolves to the utm_source or null.
 */
export const syncUtmSourceWithLeader = () => {
  const masterWindow = getLeaderWindow();

  masterWindow.postMessage({ type: 'getUtmSource' }, '*');
  dbg('info', 'sent getUtmSource message to leader');
  const returnValue = new Promise((resolve) => {
    // In case there is no master or the master doesn't respond, resolve to null
    const timeout = setTimeout(() => {
      dbg('error', 'no response from master SSP, resolving to null');
      resolve(null);
    }, 250);
    const f = (e) => {
      if (e.data.type === 'utmSource') {
        clearTimeout(timeout);
        dbg('info', 'got message from leader', e);
        resolve(e.data.source);
        window.removeEventListener('message', f);
      }
    };
    window.addEventListener('message', f);
  });
  return returnValue;
};
