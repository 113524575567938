/**
 * Vrací referera iframe, pokud v něm jsme.
 * @returns {String} url referera, pokud jsme v iframe, jinak null
 */
const getIframeReferer = () => {
  try {
    if (window.self !== window.top) {
      return document.referrer;
    } else {
      return null;
    }
  } catch (e) {
    return document.referrer;
  }
};

/**
 * Vrací url z top window, pokud je dostupná.
 * @returns {String} url z top window nebo null
 */
const getTopWindowUrl = () => {
  try {
    return top.document.location.href;
  } catch (e) {
    return null;
  }
};

const blacklistRegExp =
  /^https*:\/\/([^/]*\.facebook\.com|[^/]*\.bing\.com|[^/]*\.google\.(com|cz)|[^/]*\.imedia\.cz)\//;

/**
 * Vraci url aktualni stranky, kvuli bezpecnostnim hlavickam nemusime dostat http
 * refereru plnou cestu.
 * @returns {String} url aktualne otevrene stranky
 */
const getLocation = () => {
  return (
    [getIframeReferer(), getTopWindowUrl(), document.location.href].find(
      (url) => !!url && !blacklistRegExp.test(url)
    ) || ''
  );
};

export default getLocation;
