import { dbg } from '../utils/dbg';
import { CONSENT } from '@sklik/cmp2-common';

const FAILSAFE_DURATION = 5000;

let failsafed = false;
let tcfUIVisible = false;
let tcfUIListener = false;

export const resetConsentMetadata = () => {
  failsafed = false;
  tcfUIVisible = false;
  tcfUIListener = false;
};

/**
 * Listen to TCF events (shown CMP UI)
 */
const watchTCF = () => {
  // visible cmp dialog?
  if (window.__tcfapi && !tcfUIListener) {
    tcfUIListener = true;
    dbg('info', 'TCFAPI listener registering');
    window.__tcfapi('addEventListener', 2, (tcData, success) => {
      if (success && tcData.eventStatus === 'cmpuishown') {
        tcfUIVisible = true;
        dbg('info', 'TCFAPI listener removing');
        window.__tcfapi('removeEventListener', 2, () => {}, tcData.listenerId);
      }
    });
  }
};

watchTCF();

/**
 * Factory for creating scoped "resolver"
 */
const resolverFactory = (resolve, timingObject) => {
  return (consent, byCookie, allowElse) => {
    if (consent) {
      if (byCookie) {
        dbg('info', 'Got v2 consent from cookie', consent);
      } else {
        dbg('info', 'Got v2 consent from tcf api', consent);
      }
      timingObject.stopAll();
      resolve(consent);
    } else if (allowElse) {
      dbg('info', 'Resolved without consent');
      timingObject.stopAll();
      resolve(null);
    }
  };
};

/**
 * Get consent + cb flow
 */
const getConsentAndAttachCallback = (resolver) => {
  return CONSENT.getConsentFromCookieList((result) => {
    if (result) {
      tcfUIVisible = false;
      resolver(result, false);
    }
  });
};

/**
 * Consent checking loop. Terminated by "failsafe" | existing consent data (cookie / TCF event) | visible CMP UI
 */
const consentCheckingLoop = (resolver, timingObject) => {
  timingObject.interval.start(() => {
    dbg('info', 'Consent Checking loop run');
    watchTCF();
    const consentChecked = getConsentAndAttachCallback(resolver);
    if (consentChecked) {
      resolver(consentChecked, true);
    } else if (tcfUIVisible) {
      dbg('info', 'Consent UI shown, stopping checking loop');
      timingObject.interval.stop();
    } else if (Date.now() - timingObject.start >= FAILSAFE_DURATION && !tcfUIVisible) {
      failsafed = true;
      // we do not know when window.__tcfapi / cmp UI will exists -> failsafe for suspicious non existing state
      dbg('info', 'Stop waiting loop for consent - failsafe');
      resolver(null, false, true);
    }
  }, 500);
};

/**
 * Consent checking timeout. Terminated by existing consent data (cookie / TCF event)
 */
const consentCheckingTimeout = (resolver, timingObject) => {
  const consentChecked = getConsentAndAttachCallback(resolver);
  if (consentChecked) {
    resolver(consentChecked, true);
  }

  timingObject.timeout.start(() => {
    dbg('info', 'Consent checking timeout reached');
    resolver(null, false, true);
  }, 500);
};

/**
 * Factory for creating "scoped time object" for easy propagation
 */
const timingFactory = function () {
  return {
    start: Date.now(),
    interval: {
      _interval: null,
      start(fn, ms) {
        this._interval = window.setInterval(fn, ms);
      },
      stop() {
        window.clearInterval(this._interval);
      },
    },
    timeout: {
      _timeout: null,
      start(fn, ms) {
        this._timeout = window.setTimeout(fn, ms);
      },
      stop() {
        window.clearTimeout(this._timeout);
      },
    },
    stopAll() {
      this.interval.stop();
      this.timeout.stop();
    },
  };
};

/**
 * Get consent in async way (optionally block flow until any consent given)
 */
export const getLatestConsent = (shouldWaitForConsent) => {
  watchTCF();

  return new Promise((resolve) => {
    // create "scoped" timing object
    const timing = timingFactory();

    // create "scoped" resolverr -> resolve accorind to consent value
    const resolver = resolverFactory(resolve, timing);

    // init sync check + callback attached
    const consent = getConsentAndAttachCallback(resolver);

    // SHOULD WAIT FOR CONSENT (for consent / tcfapi)
    // checking loop -> interupted by existing consent cookie or TCF event or failsafe
    if (shouldWaitForConsent && !consent && !failsafed && !tcfUIVisible) {
      consentCheckingLoop(resolver, timing);
      dbg('info', 'Consent checking loop started -> "hard wait"');
    }

    // SHOULD NOT WAIT FOR CONSENT ("soft" waiting -> timeout)
    if (!shouldWaitForConsent) {
      consentCheckingTimeout(resolver, timing);
      dbg('info', 'Consent checking timeout started -> "timeout wait"');
    }

    // current state to resolver
    resolver(consent, true, !shouldWaitForConsent || failsafed);
  });
};

export const getLatestConsentString = async (shouldWaitForConsent) => {
  try {
    const consent = await getLatestConsent(shouldWaitForConsent);

    if (consent) {
      return consent.consentString;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
};
