import { dbg } from '../utils/dbg';
import { getImageBaseUrl } from '../utils/urls';
import { stringToBase64url } from '../utils/base64';
import { getConfig } from '../config';
import { revivePaw } from './getPawHTML';
/**
 * Generates HTML code of the info icon, which is displayed on the top right corner of the banner.
 * @param {String} coopUrl URL to the coop info page
 * @returns {String} info icon template HTML
 */
const createCoopIconTemplate = (coopUrl, align = 'right', disableAnimation = false) => {
    const infoUrl = getImageBaseUrl();
    const style = `
		width: 13px;
		height: 13px;
    margin: 0;
    padding: 0;
	`;
    const coopIcon = `<img src="${infoUrl}/coop.svg" data-cy-paw="coop" style="${style}">`;
    const textFragment = `<span style="display: ${disableAnimation ? 'block' : 'none'}; height:15px; color: #808080; font-size: 8pt;" data-cy-pawinfo="seznam-reklama">Komerční spolupráce</span>`;
    const iconFragment = `<div class="packa-icon" style="display: block; width: 15px; height: 15px; filter: brightness(85%)">${coopIcon}</div>`;
    let fragmentOrder = [textFragment, iconFragment];
    if (align === 'left') {
        fragmentOrder = fragmentOrder.reverse();
    }
    return `
		<div style="height:15px; margin: 5px;" class="packa">
			<a target="_blank" style="cursor: pointer;text-decoration: underline; text-decoration-color: #808080;display: flex;justify-content: ${align === 'left' ? 'start' : 'end'};gap: 5px;align-items: center;" class="packa-anchor" href="${coopUrl}" data-dot-data=\'{"action": "showCoopInfoPage"}\'>
        ${fragmentOrder.join('\n')}
			</a>
		</div>
	`;
};
/**
 * Appends Commercial Cooperation icon to an element. An info icon on top right corner of the banner.
 * @param {Object} params - coop parameters
 * @param {HTMLElement} element - HTML DOM element, where the icon should be inserted
 */
export const appendCoopIconToElement = (params, element) => {
    const isValidInput = element && element.insertAdjacentHTML;
    if (!isValidInput) {
        dbg('error', 'invalid arguments provided to "appendCoopIconToElement"', { params, element });
        return;
    }
    const coopUrlHash = JSON.stringify({
        a: [
            {
                ...(params?.paid && { p: params.paid }),
                ...(params?.behalf && { b: params.behalf }),
                r: [2],
            },
        ],
    });
    const { protocol, staticServer } = getConfig();
    const coopUrl = `${protocol}${staticServer}/cooperation-info#${stringToBase64url(coopUrlHash).replace(/=/g, '')}`;
    const iconElement = createCoopIconTemplate(coopUrl, params?.align, params?.disableAnimation);
    element.insertAdjacentHTML('afterbegin', iconElement);
    const animationFragments = [];
    if (params?.disableAnimation !== true) {
        animationFragments.push(params?.align === 'left' ? 'last' : 'first');
    }
    revivePaw(null, element, animationFragments);
};
