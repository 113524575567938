import { getParamFromQuerystringOrHash } from '../utils/helpers/getUrlParams';
import { omitWww } from '../utils/urls';

/** Internal services blacklisted from using CMP */
const blacklist = [];

export const inBlacklist = (hostname) => {
  return (
    blacklist.includes(omitWww(hostname)) ||
    // for testing pourposes
    getParamFromQuerystringOrHash('ignore_cmp') === '1'
  );
};

/** Services waiting for consent cookie (delayed getAds) */
const whitelist = [
  'forum24.cz',
  'instory.cz',
  'ceskenoviny.cz',
  'magazinplus.cz',
  'moviezone.cz',
  'zing.cz',
  'svoboda.info',
  'autickar.cz',
  'fandimeserialum.cz',
  'techfocus.cz',
  'tripmania.cz',
  'autoforum.cz',
  'zivotvcesku.cz',
  'hlidacipes.org',
  'kinobox.cz',
];

export const inWhitelist = (hostname) => {
  return (
    whitelist.includes(omitWww(hostname)) ||
    // for testing pourposes
    getParamFromQuerystringOrHash('ads_consent_wait') === '1'
  );
};

const chunksWhitelist = ['kuchticka.cz'];

export const inChunksWhitelist = (hostname) => {
  return (
    chunksWhitelist.includes(omitWww(hostname)) ||
    // for testing pourposes
    getParamFromQuerystringOrHash('cmp_chunks') === '1'
  );
};
