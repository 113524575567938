import callPositionErrorCallback from '#Modules/ads/callPositionErrorCallback';
import { optionsParams } from '#Modules/config/configValues';
import { dbg } from '#Modules/utils/dbg';
const isOptionsParam = (key) => {
    return optionsParams.includes(key);
};
/**
 * Validate position object and prepare it for ad request
 */
export const validatePosition = (positionOrig, opt) => {
    dbg('info', '### check ad position data ###');
    const position = { ...positionOrig };
    const positionEl = typeof position.id === 'string' ? document.getElementById(position.id) : position.id;
    // callback or position element must be defined, otherwise is not possible to render ad
    if (!opt?.AMPcallback && !position.callback && !positionEl) {
        const errorMessage = `No callback or invalid ID passed to IM (zoneId: ${position.zoneId})`;
        // eslint-disable-next-line no-console
        console.log(`%c !!! ${errorMessage}`, 'font-size:20px; font-weight:bold; background:#ec0000; color: #fff');
        callPositionErrorCallback(position, errorMessage);
        dbg(errorMessage);
        return null;
    }
    const width = position.width || positionEl?.offsetWidth;
    const height = position.height || positionEl?.offsetHeight;
    // update position object only if the dimensions are positive numbers otherwise delete the key
    if (width > 0) {
        position.width = width;
    }
    else {
        delete position.width;
    }
    if (height > 0) {
        position.height = height;
    }
    else {
        delete position.height;
    }
    // element must have at least one valid size otherwise is not possible to render ad
    // if noSize is allowed, we can skip this check
    if (positionEl && !width && !height && !position?.options?.noSize) {
        const errorMessage = `No valid size of container or no size configuration passed: (${width}, ${height}).`;
        callPositionErrorCallback(position, errorMessage);
        dbg(errorMessage);
        return null;
    }
    if (position?.options?.noSize) {
        dbg('noSize allowed in position:', position.zoneId);
    }
    dbg('callback and container check .. OK');
    // validate position options keys
    const optionsEntries = Object.entries(position.options || {});
    const validOptions = optionsEntries.reduce((acc, [key, value]) => {
        if (isOptionsParam(key)) {
            acc[key] = value;
        }
        return acc;
    }, {});
    // set options to only valid options and add sspRendering flag
    position.options = { ...validOptions, ...{ sspRendering: !position.callback } };
    dbg(position.zoneId + ' position object prepared: ', position);
    return position;
};
