import { dbg } from '../../utils/dbg';
import { getTargetElement } from '../getTargetElement';

// Increment, used to generate id
let increment = 0;

/**
 * Id generator in format 'sznssp-[zoneId]-[increment]'
 * @return {String} Generated id
 */
const generatorAdId = (zoneId) => {
  return `sznssp-${zoneId}-${++increment}`;
};

/**
 * Id synchronization between ad element (div) and data in positionsStore
 * @param {Object} ad server response
 * @param {Object} data zones description
 */
const syncId = (ad, data) => {
  try {
    dbg('info', '### start of id synchronization ###');
    const targetElement = getTargetElement(ad, data);
    if (!targetElement) {
      dbg('Element not found. syncId was not run');
      return;
    }

    if (targetElement.id) {
      dbg('syncId by id from element (div)');
      ad.id = targetElement.id;
      return;
    }
    if (ad.id && typeof ad.id === 'string') {
      dbg('syncId by id from positionsStore');
      targetElement.id = ad.id;
      return;
    }
    dbg('generating id and syncId');
    const id = generatorAdId(ad.zoneId);
    ad.id = id;
    targetElement.id = id;
  } catch (error) {
    dbg('error', 'syncId has an error:', error);
  }
};

export { syncId };
