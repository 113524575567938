import { dbg } from '../utils/dbg';

let cids = [];
const CIDS_LIMIT = 20;

/**
 * Přidává cids z parametru opt.cids
 * @param {Object} newCids  cids z parametru opt.cids
 */
const setCids = (newCids) => {
  if (Array.isArray(newCids)) {
    cids = newCids.slice(0, CIDS_LIMIT);
  }
};

/**
 * Getter
 * @return {Array<String>} pole cidů
 */
const getCids = () => cids;

/**
 * Sbíráme CIDy volitelně z ssp response
 * @param {Object} response Data od ssp backendu (/xhr)
 */
const addCidsFromResponse = (response) => {
  try {
    response.ads.forEach((ad) => {
      if (ad?.bidIds?.cid && !cids.includes(ad.bidIds.cid)) {
        cids.push(ad.bidIds.cid);
      }
      if (cids.length > CIDS_LIMIT) {
        cids.splice(0, cids.length - CIDS_LIMIT);
      }
    });
  } catch (e) {
    dbg('error', e);
  }
};

export { setCids, getCids, addCidsFromResponse };
