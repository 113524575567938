import { dbg } from '../utils/dbg';
import { getCompleteData, addPositions, runPositionsWatcher, getBasicPositionsInfo } from './adRepository';
import processAdData from './processAdData';
import { sendDataToExtension, sendSspAdsLoaded, sendSspAdsChange, emitDebugEvent } from '../utils/events';
import { getJson } from '../utils/helpers/getJson';
import { runInfoCallback } from '../utils/runInfoCallback';
import { getConfig } from '../config';
import viewCheckHandler from '../impress/visibilityMeasure';
import { appendDspInfoEl } from './dspTest';

/**
 * @module sssp/done
 */

/**
 * Fills in potential missing information
 * @param {Object} ad - Response object
 * @param {Object} position - Position object
 * @param {String} requestId - Identifier of ad request
 */
const fillInAdData = (ad, position, requestId) => {
  ad.tracking = ad.tracking || { served: null, visible: null }; // video reklama VAST nema tracking
  ad.id = position.id;
  ad.requestId = requestId;
};

/**
 * Zpracuje reklamu po nacteni
 * @param serverResponse {Object} - reklama z sssp
 * @param positions {Object} - definice pozic
 * @param opt {Object} - volitelny konfiguracni objekt
 * @param requestData {Object} - originální pozadavek na reklamu
 */
const done = function (serverResponse, positions, opt, requestData) {
  const { callCallbackOnError } = getConfig();

  dbg('info', '### starting _done ###');
  emitDebugEvent('doneCallStart', { positions });

  const parsedData = getJson(serverResponse);

  if ((parsedData?.error || !parsedData) && callCallbackOnError) {
    const errorMessage = parsedData?.error ? parsedData : { error: 'No valid JSON data from server response.' };
    requestData.zones.forEach((ad) => {
      ad.callback(errorMessage, ad);
    });
  }

  if (!parsedData) {
    return;
  }
  const { ads, requestId } = parsedData;
  if (!ads) {
    return;
  }

  emitDebugEvent('doneDataParsed', { parsedData });

  ads.forEach((ad, index) => {
    fillInAdData(ad, positions[index], requestId);
  });
  // Send raw request/response data to Chrome extension
  sendDataToExtension(requestData, ads);

  const completeData = getCompleteData(positions, ads);

  // volani z AMPHTML
  if (opt && opt.AMPcallback) {
    // eslint-disable-next-line new-cap
    opt.AMPcallback(ads);
    emitDebugEvent('doneAMPCallback', { ads });
    return;
  }

  completeData.forEach((adObject) => {
    const { ad, data } = adObject;

    // Attempt to run infoCallback, if set in ad position
    runInfoCallback(ad, data);

    if (ad.type === 'error') {
      addPositions([adObject]);

      const error = (ad.dbginfo && ad.dbginfo.error) || '';
      const errorMessage = `Position type error: ${error}`;

      emitDebugEvent('donePositionError', { ad });
      dbg('error', errorMessage);
      return;
    }

    /* Send event with ad data. Some services require this to work properly. */
    sendSspAdsLoaded(ad, data);

    /* Continue processing this particular ad */
    processAdData(ad, data);
  });

  emitDebugEvent('doneEnded', {});

  // Init visibility measuring
  viewCheckHandler();
  emitDebugEvent('viewHandlerEnabledCall', {});

  // if watcher registered, call it with udpated positions
  runPositionsWatcher();

  // Generating a custom event 'sspadschange' with ad data
  sendSspAdsChange(getBasicPositionsInfo());

  appendDspInfoEl(parsedData);
};

export default done;
