const ua = window.navigator.userAgent;

/** Whitelisted substrings in User Agent string */
const browserWhitelist = ['Firefox', 'Opera', 'OPR', 'SznProhlizec', 'Edg', 'MSIE', 'Trident/'];

/**
 * Tries to fish out whether we're in Chrome and nothing else.
 * See #CMP-413
 * @returns {boolean}
 */
const isThisChrome = () => {
  /** Blacklisted substrings in User Agent string */
  const browserBlacklist = ['Firefox', 'OPR', 'CriOS', 'Edg', 'SznProhlizec', 'UCBrowser', 'YaBrowser', 'Yowser'];

  if (!ua.includes('Chrome')) {
    return false;
  }

  return !browserBlacklist.some((browserName) => {
    return ua.includes(browserName);
  });
};

/**
 * Tries to fish out whether we're in Safari browser.
 * @returns {boolean}
 */
export const isThisSafari = () => ~ua.indexOf('Safari/') && !~ua.indexOf('Chrome/') && !~ua.indexOf('Chromium/');

/**
 * Tries to fish out whether we're in allowed browser.
 * @returns {boolean}
 */
const isThisAllowedBrowser = () => {
  return (
    isThisChrome() ||
    isThisSafari() ||
    browserWhitelist.some((browserName) => {
      return ua.includes(browserName);
    })
  );
};

export const isAllowed = isThisAllowedBrowser();
