import { getLatestConsentString } from './consentCookie';
import { getAdByImpressId } from '../ads/adRepository';
import { getConfig } from '../config';

export const handleConsentRequest = async (event) => {
  let message = null;
  try {
    message = JSON.parse(event.data);
    if (!message.type || message.type !== 'SSSP_GET_CONSENT') {
      return;
    }
  } catch (e) {
    return;
  }

  const consent = await getLatestConsentString();

  if (!consent) {
    return;
  }

  const ad = getAdByImpressId(message.impressId);
  const iframe = ad.containerElement.querySelector('iframe');
  const { protocol, staticServer } = getConfig();
  const targetOrigin = protocol + staticServer;
  if (iframe) {
    const consentMessage = {
      type: 'SSSP_USER_CONSENT',
      consent,
    };
    iframe.contentWindow?.postMessage(JSON.stringify(consentMessage), targetOrigin);
  }
};

export const enableConsentProvider = () => {
  window.addEventListener('message', handleConsentRequest);
};
