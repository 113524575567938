const sspScriptSrcRegEx = /($|\/)ssp\.js\?/;
/**
 * For "ssp.js?cmpURL=xxx&dotURL=yyy" function returns
 * {cmpURL: "xxx", dotURL: "yyy"}
 */
export const getScriptParams = () => {
    const paramsObj = {};
    const scripts = document.querySelectorAll('script') || [];
    const sspScript = Array.from(scripts).find(({ src }) => {
        return sspScriptSrcRegEx.test(src);
    });
    if (sspScript) {
        const params = new URLSearchParams(sspScript.src.split('?')[1]);
        for (const [key, value] of params.entries()) {
            paramsObj[key] = value;
        }
    }
    return paramsObj;
};
