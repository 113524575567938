export const params = [
    'zoneId',
    'width',
    'height',
    'minWidth',
    'minHeight',
    'options',
    'serviceId',
    'contentId',
    'cidsAutoSend',
];
export const optionsParams = [
    'section',
    'collocation',
    'passons',
    'flags',
    'spotId',
    'minDuration',
    'maxDuration',
    'videoFormats',
    'protocols',
    'skippable',
    'jsonPreloadDisabled',
    'infoCallback',
    'noSize',
    'nativeTemplate',
    'nativeVersion',
    'sspRendering',
    'sfExpandZIndex',
    'pawTimeout',
    'pawDisable',
    'disableScaling',
    'tags',
    'reloadCount',
    'doNotParseVastData',
];
export const allowedSources = {
    'hp_feed': 'hp_feed', // deprecated, but we cannot afford to break websites using this
    'sekce-z-internetu': 'hp_feed',
    'denni-tisk': 'novinky',
    'novinky': 'novinky',
    'media': 'media',
    'z-boxiku': 'hp_box',
    'hp_box': 'hp_box',
    'search': 'search',
    'software': 'software',
    'sbrowser': 'sbrowser',
    'radio': 'radio',
    'tvseznam': 'tvseznam',
};
export const dopUrlParameters = [
    'dop_ab_variant',
    'dop_req_id',
    'dop_source_zone_name',
    'dop_id',
    'dop_vert_id',
    'dop_vert_ab',
];
