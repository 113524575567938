/* eslint-disable no-console */
import { dbg } from '../utils/dbg';
import { getTimestamp } from '../utils/timestamp';

const SERVICE = 'partner3';
const ACTION = 'event';
const CMP = true;
const TIMESTAMP = getTimestamp();

class DOT {
  /**
   * Configures DOT script and sends impress.
   */
  config() {
    try {
      if (!window.DOT) {
        dbg('info', 'window.DOT is missing');
        return;
      }
      this.instance = window.DOT.getNewInstance();

      const config = {
        service: SERVICE,
        impress: false,
        mousedown: false,
        load: false,
        cmp: CMP,
        timestamp: TIMESTAMP,
      };

      this.instance.cfg(config);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Sends hit.
   * @param {string} action
   * @param {Object} data
   */
  _hit(action, data) {
    try {
      data.d.cmp = CMP;
      data.d.timestamp = TIMESTAMP;
      this.instance.hit(action, data);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Sends info that cookie was copied from global storage to partner without our CMP.
   */
  cookieCopied() {
    this._hit(ACTION, {
      d: {
        action: 'cookieCopied',
      },
    });
  }
}

export default new DOT();
