import { dbg } from '../utils/dbg';
import { getPositions } from '../ads/adRepository';
import { isBranding } from '../rendering/getPawHTML';

let observer = null; // one instance for each ssp script
const elmsIntersections = {}; //elms (ids) being measured (timer)
const VISIBLE_FOR = 1000; // ms treshlold for visibility to be valid

// - element is being registered for intersection
// - element already intersected enough)
// - element intersection status / timer reset
const resetElmIntersection = (elmId, purge = false) => {
  if (elmsIntersections[elmId] && elmsIntersections[elmId].timer) {
    window.clearTimeout(elmsIntersections[elmId].timer);
  }
  if (purge) {
    delete elmsIntersections[elmId];
  } else {
    elmsIntersections[elmId] = {};
  }
};

// element is being interseted (start / persist timer)
const setElmIntersection = (elmId) => {
  elmsIntersections[elmId] = {
    timer: (elmsIntersections[elmId] || {}).timer || window.setTimeout(() => unobserveAndSend(elmId), VISIBLE_FOR),
  };
};

// "forget" all already intersected elements
export const clearElmsIntersections = () => {
  for (const elmId in elmsIntersections) {
    resetElmIntersection(elmId, true);
  }
};

/**
 * Perform visibility hits
 */
const visible = function (visibleUrls) {
  if (!visibleUrls) {
    return; // non visible - video ad
  }
  dbg('info', '### visible hits ###');
  visibleUrls.forEach(function (item) {
    dbg('visible URL:', item);
    var i = new Image(1, 1);
    i.onerror = () => dbg('error', 'Failed count visible URL:', item);
    i.src = item;
  });
};

/**
 * Get HTMLElement (by ID or reference)
 */
const getElementFromRepositoryItem = (adUnit) => {
  const pos = adUnit.data;
  let adElement = null;
  if (pos.id) {
    adElement = typeof pos.id === 'string' ? document.getElementById(pos.id) : pos.id;
  } else if (adUnit.containerElement) {
    adElement = adUnit.containerElement;
  }

  return adElement;
};

/**
 * AdUnit created in adRepository, but element not present in dom -> wait (check max 3x)
 */
const waitForElement = (adUnit) => {
  let tries = 0;
  return new Promise((resolve) => {
    const interval = window.setInterval(() => {
      if (tries >= 3) {
        window.clearInterval(interval);
        resolve(null);
      }
      const el = getElementFromRepositoryItem(adUnit);
      if (el) {
        window.clearInterval(interval);
        resolve(el);
      }
      tries++;
    }, 250);
  });
};

/**
 * Is visible for minimum time interval? Send visibility impress + unobserve
 */
const unobserveAndSend = (elmId) => {
  const elmById = document.getElementById(elmId);
  const adUnit = getPositions().find((pos) => elmById && getElementFromRepositoryItem(pos) === elmById);
  if (adUnit) {
    dbg('info', '### visibility counted ###', elmId);

    visible(adUnit.ad.tracking.visible);
    observer.unobserve(elmById);
    resetElmIntersection(elmId);
  }
};

/**
 * Observe all positions with specified element
 */
export const observePositions = async () => {
  if (observer) {
    const positions = getPositions().filter(({ ad }) => ad.type !== 'empty');
    for (let pIndex = 0; pIndex < positions.length; pIndex++) {
      const adUnit = positions[pIndex];
      let adElement = getElementFromRepositoryItem(adUnit);
      if (!adElement) {
        adElement = await waitForElement(adUnit);
      }
      if (adElement && adElement.id && !elmsIntersections[adElement.id]) {
        resetElmIntersection(adElement.id);
        observer.observe(adElement);
      }
    }
  }
};

/**
 * Observe intersections for each position (50% dimensions visibility is treshold)
 */
const viewCheckHandler = () => {
  if ('IntersectionObserver' in window) {
    if (!observer) {
      observer = new IntersectionObserver(
        (intersections) => {
          intersections.forEach(({ target, isIntersecting, intersectionRatio }) => {
            const adUnitByTarget = getPositions().find(({ ad }) => {
              return ad.containerElement === target || (ad.id && ad.id === target.id);
            });

            // for branding we do not validate intersection ratio
            const doNotValidateIntersectionRatio = !!(adUnitByTarget && isBranding(adUnitByTarget.ad.width));

            if (isIntersecting && (doNotValidateIntersectionRatio || Math.round(intersectionRatio))) {
              // is intersecting at least by 50% -> start timer -> send visibility hit and unobserve
              setElmIntersection(target.id);
            } else {
              // is not intersecting at least by 50% -> clear timer
              resetElmIntersection(target.id);
            }
          });
        },
        { threshold: [0, 0.5, 1] }
      );
    }
    observePositions();
  }
};

export default viewCheckHandler;
