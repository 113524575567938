import { dbg } from './dbg';
import { postscribe } from '@iva/postscribe';

const reportError = (msg) => {
  const data = {
    msg,
    script: 'postscribe',
    referrer: document.referrer,
  };

  navigator.sendBeacon?.('https://confessor.iva.seznam.cz/postscribe', new URLSearchParams(data));
};

export const writeTo = (dest, html) => {
  if (!document.writeTo) {
    dbg('Internal writeTo not available');
    return;
  }

  dbg('Using postscribe for writeTo');
  postscribe(dest, html, {
    autoFix: false,
    error: (e) => {
      const message = {
        msg: e.msg,
        html,
      };

      if (window._sssp?.ad) {
        message.sspMetadata = window._sssp.ad;
      }

      reportError(JSON.stringify(message));
    },
  });
};

export const setupDocumentWrite = () => {
  if (document.writeTo) {
    return;
  }

  document.writeTo = (dest, html) => {
    dbg('ad used postscribe');
    postscribe(dest, html, {
      autoFix: false,
      error: (e) => reportError(e.msg + '; postscribe was called from ad.'),
    });
  };
};
