// Global
export const SSP_URL = {
    PROTOCOL: 'https://',
    HOSTNAME: 'ssp.seznam.cz',
    IFRAME_HOSTNAME: 'ssp.im.cz',
    PATH_TO_EMPTY: '/static/html/empty.html',
    PATH_TO_CMP: '/cmp',
};
// Rendering
export const INT_PAW_LINK = 'https://o.seznam.cz/ochrana-udaju/personalizace-obsahu-a-reklamy/personalizovana-reklama/';
export const PAW_RENDER_DELAY = 42;
export const DSP_LIST = ['SKLIK', 'ADVERT', 'OGAR', 'ONEGAR', 'APPNEXUS', 'PUBMATIC'];
// Collocation
export const COLLOCATION_WHITELIST = [
    'seznamzpravy.cz',
    'localhost', // because of Cypress
];
export const COLLOCATION_UNSAFE_KEYWORDS = ['Válka Rusko-Ukrajina', 'Válka v Izraeli'];
export const COLLOCATION_SAFE_KEYWORDS = [
    'Domácí',
    'Byznys',
    'Podcasty',
    'Život v Česku',
    'Politika',
    'Finance',
    'Rozhovory',
    'Názory',
];
export const COLLOCATION_FLAG_NOWAR = 'noWar';
export const COLLOCATION_FLAG_SAFE = 'BrandSafety';
// Hit
export const HIT_MODIFICATION_TITLE = 'unfreeze_sspjs';
// DOT events
export const DOT_FIRST_IMPRESS_EVENT = 'szn:dot:impressdone';
export const IMPRESS_TIMEOUT = 5000;
export const INTERNAL_SERVICES = [
    'www.seznam.cz',
    'hry.seznam.cz',
    'www.horoskopy.cz',
    'www.novinky.cz',
    'www.seznamzpravy.cz',
    'www.prozeny.cz',
    'www.super.cz',
    'www.sport.cz',
    'www.garaz.cz',
    'www.stream.cz',
    'www.televizeseznam.cz',
    'www.firmy.cz',
    'www.sauto.cz',
    'www.sreality.cz',
    'www.sbazar.cz',
    'www.zbozi.cz',
    'mapy.cz',
    'sdovolena.seznam.cz',
    'search.seznam.cz',
    'www.kupi.cz',
    'www.expresfm.cz',
    'www.classicpraha.cz',
    'atlas.mapy.cz',
    'login.szn.cz',
    'email.seznam.cz',
    'slovnik.seznam.cz',
    'podcasty.seznam.cz',
    'www.volnamista.cz',
    'tv.seznam.cz',
    'reporter.seznam.cz',
    'ucet.seznam.cz',
    'pocasi.seznam.cz',
    'slearning.cz',
    'special.novinky.cz',
    'online.sport.cz',
    'en.mapy.cz',
    'sk.mapy.cz',
    'pl.mapy.cz',
    'de.mapy.cz',
    'registrace.seznam.cz',
    'en.firmy.cz',
    'uk.mapy.cz',
    'native.seznamzpravy.cz',
    'advise.novinky.cz',
    'medium.seznam.cz',
    'partner.seznam.cz',
];
// Debugger
export const SERVICE_NAME = 'SSSP';
export const LOG_COLOR_DEFAULT = '#fff';
export const LOG_COLOR_BG = '#a90101';
// AD-INFO PAGE
export const AD_INFO_REASONS = {
    PROFILING: 'Jedná se o\u00A0behaviorálně cílenou reklamu.',
    BASIC_ADV: 'Jedná se o\u00A0náhodně zobrazenou reklamu, nebo reklamu na základě hledaného dotazu.',
    GEOLOCATION: 'Jedná se o\u00A0geograficky cílenou reklamu.',
};
// AD-INFO PAGE
export const COOP_INFO_REASONS = {
    BASIC_ADV: 'Jedná se o\u00A0náhodně zobrazenou komerční spolupráci, nebo spolupráci na základě hledaného dotazu.',
};
export const AD_INFO_INT_PRIVACY_URL = 'https://o.seznam.cz/ochrana-udaju/personalizace-obsahu-a-reklamy/personalizovana-reklama/';
export const AD_INFO_EXT_PRIVACY_URL = 'https://partner.seznam.cz/app/ochrana-soukromi/';
export const AD_INFO_CLIENT_ZONE_URL = 'https://napoveda.seznam.cz/cz/klientska-zona/nastaveni-kz/kz-zakladni-informace/';
export const COOP_INFO_PRIVACY_URL = 'https://napoveda.seznam.cz/cz/login/produkt-seznam-bez-reklam/#vyjimky-zobrazeni';
