/**
 * Generate expiration string (UTC format)
 */
const getExpireTime = ([value, units]) => {
    const multipliers = {
        seconds: 1000,
        minutes: 60,
        hours: 60,
        days: 24,
    };
    const unitIndex = Object.keys(multipliers).findIndex((unit) => unit === units);
    const multiplierArray = !~unitIndex ? [] : Object.values(multipliers).slice(0, unitIndex + 1);
    const offset = multiplierArray.reduce((acc, current) => {
        return acc * current;
    }, value);
    const date = new Date();
    date.setTime(date.getTime() + offset);
    return date.toUTCString();
};
/**
 * Get whole string (serialized object with all cookie related params)
 */
export const getCookieString = (cookieData, remove = false) => {
    if (!cookieData.name) {
        throw new Error('No cookie name provided');
    }
    const defaults = {
        value: '',
        expires: [365, 'days'],
        path: '/',
        secure: null,
        sameSite: null,
        domain: null,
    };
    const cookieValues = { ...defaults, ...cookieData };
    const { name, value, expires, path, secure, sameSite, domain } = cookieValues;
    const expireTime = remove ? new Date(0).toUTCString() : getExpireTime(expires);
    const cookieValue = [
        `${name}=${value}`,
        `Expires=${expireTime}`,
        `Path=${path}`,
        domain ? `Domain=${domain}` : '',
        sameSite ? `SameSite=${sameSite}` : '',
        secure ? 'Secure' : '',
    ];
    const filteredValues = cookieValue.filter((el) => !!el);
    return filteredValues.join(';');
};
/**
 * Get single cookie tuple ([key, value])
 */
const getCookieTuple = (singleCookie) => {
    const separatorIndex = singleCookie.indexOf('=');
    const key = singleCookie.substring(0, separatorIndex);
    const value = singleCookie.substring(separatorIndex + 1);
    return [key.trim(), value.trim()];
};
/**
 * Get cookie value by name
 */
export const getCookie = (requestedCookieName) => {
    const cookies = document.cookie.split(';');
    return cookies.reduce((acc, item) => {
        if (item) {
            const [key, value] = getCookieTuple(item);
            if (key === requestedCookieName) {
                acc = value;
            }
        }
        return acc;
    }, null);
};
/**
 * Set cookie (value, exipration, domain, path, ...)
 */
export const setCookie = (cookieData) => {
    return (document.cookie = getCookieString(cookieData));
};
/**
 * Remove cookie (set negative expiration + empty value)
 */
export const removeCookie = (cookieData) => {
    return (document.cookie = getCookieString(cookieData, true));
};
