import { dbg } from '../../utils/dbg';
import served from '../../impress/served';
import { prerenderAdToPage } from '../prerenderAdToPage';
import { writeTo } from '../../utils/documentwrite';

/**
 * Zapise reklamu typu code primo do stranky
 * @param ad {Object} - reklama z sssp
 * @param data {Object} - definice pozice
 **/
const writeCodeToPage = function (ad, data) {
  dbg('info', '### starting direct ad writitng ###');
  dbg('with parameters', ad, data);

  if (typeof ad.data === 'string' && ad.data.includes(`\"type\": \"code\"`) && ad.data.includes(`\"creative\":`)) {
    dbg('"writeCodeToPage" terminated, not supported data (not parsed raw data)');
    return;
  }

  const adContent = prerenderAdToPage(ad, data);

  try {
    writeTo(adContent, ad.data);
  } catch (e) {
    dbg('error', e);
  }

  // zapocitame impresse
  served(ad.tracking.served);
};

export default writeCodeToPage;
