let lastRusId = null;
let lastConsent = '';

export const setLastRusId = (rusId) => {
  lastRusId = rusId;
};

export const setLastConsent = (consent) => {
  lastConsent = consent;
};

export const canUseCurrentConsent = (currentRusId, currentConsent) => {
  if (!currentRusId || !lastRusId) {
    lastRusId = '';
    return true;
  }

  // rusId changed but consent is still same. Cannot send current consent.
  if (currentRusId !== lastRusId && lastConsent === currentConsent) {
    return false;
  }

  return true;
};
