import init from '#Modules/init';
import apiObject from '#Modules/global/api';
import { dbg } from '#Modules/utils/dbg';
import { sendHit } from '#Modules/utils/sendHit';
import { doNotInit } from '#Modules/config/globalFlags';
import { HIT_MODIFICATION_TITLE } from '#Constants';
/**
 * Prepare public API object + assign
 */
export const constructSssp = () => {
    const sssp = apiObject();
    _setNamespace(sssp);
    _init();
    return sssp;
};
/**
 * Clear "stubbed" global sssp object
 */
export const _cleanUpNamespace = () => {
    if (window.sssp?.isStub) {
        // @ts-ignore
        delete window.sssp;
    }
};
/**
 * Assign global sssp object
 */
export const _setNamespace = (sssp) => {
    _cleanUpNamespace();
    // assign sssp namespace (proxy vs basic object)
    if (!window.sssp) {
        dbg('info', '### exporting sssp object to window');
        if ('Proxy' in window) {
            _constructProxy(sssp);
        }
        else {
            window.sssp = sssp;
        }
    }
    else {
        dbg('info', '### sssp already exists - exiting');
    }
};
/**
 * In supported environment -> proxy (with "set" access logging)
 */
const _constructProxy = (sssp) => {
    const proxyHandler = {
        set(obj, prop, value) {
            obj[prop] = value;
            // log modification of module properties
            sendHit({ t: HIT_MODIFICATION_TITLE, i: '1', r: top.location.origin, z: '1' });
            return true;
        },
    };
    Object.defineProperty(window, 'sssp', {
        configurable: true,
        get() {
            return new Proxy(sssp, proxyHandler);
        },
        set(value) {
            // log modification of the whole module
            sendHit({ t: HIT_MODIFICATION_TITLE, i: '1', r: top.location.origin, z: '1' });
            Object.defineProperty(window, 'sssp', { value });
        },
    });
};
/**
 * init ssp workflow (if not disabled by global flag)
 */
const _init = () => {
    if (!doNotInit()) {
        dbg('info', '### initializing sssp');
        init();
    }
    else {
        dbg('info', '### variable window._sspDoNotInit is set, skipping ssp initialization');
    }
};
