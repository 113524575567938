import { dbg } from '../utils/dbg';
import { getConfig } from '../config';
import { emitDebugEvent } from '../utils/events';
import { getCookie } from '../utils/cookies';
import { extractId } from '../utils/helpers/extractId';
import { getUrlParams } from '../utils/helpers/getUrlParams';
import { getDopPayloadValues } from '../session/abTest';
import { getLatestConsentString } from '../cmp/consentCookie';
import { getIS } from '../utils/identityStorage';
import { canUseCurrentConsent, setLastRusId, setLastConsent } from '../cmp/consentAccountSwitch';
import { SID_UTILS } from '@iva/utils';
import { getDopSourceData } from '../session/dopSource';
import { isItDspTestPage } from '../ads/dspTest';
import { getCurrentSznUser, USER_TIMEOUT } from '../utils/user';

/**
 * Extracts arbitrary params from URL unrelated to main SSP functionality to be
 * passed to the server.
 * format: https://example.com/?ssp-param-key=value&ssp-param-another=value2
 */
const getArbitraryParams = () => {
  const urlParamEntries = Object.entries(getUrlParams());

  if (!urlParamEntries.length) {
    return;
  }

  return urlParamEntries.reduce((acc, entry) => {
    const [param, value] = entry;
    if (param.includes('ssp-param-')) {
      const paramName = param.substring(10);
      acc[paramName] = value;
    }
    return acc;
  }, {});
};

/**
 * Vyrobime prefixove parametry pro url na ziskani reklam
 * @param zones   {Array} jednotlivé zóny
 * @param opt     {Object} opt parametry volani
 * @param cids    {Array} kolekce definovaných cidů (z modulu + volitelně z ss response objektů)
 */
const createRequestPayload = async (zones, opt, cids) => {
  const config = getConfig();
  const { source, allowedSources, pvId, site, adblock, ab } = { ...config, ...opt?.config };
  dbg('info', 'Source from config:', source);

  let { premium, rusId, said } = config;
  let login = null;
  const IS = getIS();
  const arbitraryParams = getArbitraryParams();
  dbg('info', '### build prefix ###');
  const payload = {
    pvId,
  };

  if (source && source !== '') {
    if (!(source in allowedSources)) {
      dbg('error', 'Source not allowed:', source);
      emitDebugEvent('sourceNotAllowed', { source });
    } else {
      payload.source = source;
    }
  }

  if (opt?.AMPcallback) {
    payload.amp = true;
  }

  if (site !== '') {
    payload.site = site;
  }

  if (adblock !== '') {
    payload.adblock = adblock;
  }

  if (ab && Object.keys(ab).length > 0) {
    payload.ab = ab;
  }

  /* If we found an AB test variant in session cookie, report it. */
  const dopAbTestData = getDopPayloadValues();

  if (dopAbTestData.dop_ab_variant) {
    // attach dop_source data (same as in spenttime hits from ssp)
    const dopSourceData = getDopSourceData();

    payload.ab = {
      ...payload.ab,
      ...dopAbTestData,
      // eslint-disable-next-line camelcase
      ...(dopSourceData ? { dop_source: JSON.stringify(dopSourceData) } : {}),
    };
  }

  // Temporary for test DOP-11655
  if (site) {
    const searchParams = {};

    try {
      const url = new URL(site);

      const urlOrigin = url.origin;
      const siteHashToParams = url.hash.replace('#', '?');
      const hashUrl = new URL(`${urlOrigin}${siteHashToParams}`);

      hashUrl.searchParams.forEach((value, key) => {
        url.searchParams.append(key, value);
      });

      const allUrlParams = Object.fromEntries(url.searchParams.entries());

      Object.assign(searchParams, allUrlParams);
    } catch (error) {
      // no op
    }

    const dopKeys = ['dop_ab_variant', 'dop_req_id', 'dop_source_zone_name'];
    const hasSiteDopTestData = dopKeys.some((key) => searchParams[key]);

    if (hasSiteDopTestData) {
      payload.ab = dopKeys.reduce(
        (result, key) => {
          if (searchParams[key]) {
            result[`site_${key}`] = searchParams[key];
          }
          return result;
        },
        { ...payload.ab }
      );
    }
  }

  /* sid cookie */
  const sid = getCookie('sid');
  if (SID_UTILS.isValidSid(sid)) {
    payload.sid = sid;
    payload.dsid = sid;
  }

  /* SZN login flags (from config or badge) (fast resolve on "no szn login" sites) */
  login = (await getCurrentSznUser()) || {};

  premium = typeof login.premium === 'boolean' ? login.premium : premium;
  rusId = login.uid || rusId;
  said = login.said || said;

  // user flags (with cookie fallback)
  const userFlags = login.encodedFlags || getCookie('user-flags');
  if (userFlags) {
    payload.userFlags = userFlags;
  }

  /* euconsent cookies */
  const consent = await getLatestConsentString();

  if (consent && canUseCurrentConsent(rusId, consent)) {
    payload.consent = consent;
    setLastConsent(consent);
  }

  if (typeof rusId === 'number' && !isNaN(rusId)) {
    payload.rusId = rusId;
    setLastRusId(rusId);
  }

  if (typeof premium === 'boolean') {
    payload.premium = premium;
  }

  if (said && typeof said === 'string') {
    payload.said = said;
  }

  if (cids && cids.length) {
    payload.cids = cids;
  }

  /** Add grantConset from url params for DSP testing page*/
  if (isItDspTestPage()) {
    const grantConsentfromUrl = getUrlParams()?.grantConsent;
    if (grantConsentfromUrl === 'true') {
      payload.grantConsent = true;
    }
  }

  /** Add grantConset from opt for DSP testing page */
  if (opt?.grantConsent === true) {
    payload.grantConsent = opt.grantConsent;
  }

  /** Allows for passing arbitrary params from URL. Used by sablony for debugging */
  if (arbitraryParams) {
    payload.customParams = arbitraryParams;
  }

  payload.zones = zones.map((zone) => ({
    ...zone,
    id: extractId(zone),
  }));

  // IS - identity-storage
  if (IS) {
    payload.ids = {
      ...IS.prepareIdsForBe({
        ...IS.getIdentities({ cacheEnough: true, justSettled: false }, { login: { justSettled: true } }), // login just fresh, rest from cache
      }),
    };
  }

  return payload;
};

export default createRequestPayload;
