import { waitForLogin } from '#Modules/utils/identityStorage';
import { getConfig } from '#Modules/config';
export const USER_TIMEOUT = 5000;
/**
 * Helper methods for ssp.config.activeUser validation (structure for login|logout|forget is required)
 */
const _isValidRusId = (rusId) => rusId && typeof rusId === 'number' && !isNaN(rusId);
const _isValidUserFlags = (userFlags) => userFlags && typeof userFlags === 'string';
const _isValidActiveLoggedUser = (user) => {
    const { uid, state, encodedFlags } = user;
    return !!(_isValidRusId(uid) && ['login', 'logout'].includes(state) && _isValidUserFlags(encodedFlags));
};
const _isValidActiveForgottenUser = (user) => {
    const { uid, state, encodedFlags } = user;
    return !!(!uid && state === 'forget' && !encodedFlags);
};
export const isValidActiveUserInConfig = (activeUser) => {
    try {
        return !!(activeUser &&
            (_isValidActiveLoggedUser(activeUser) || _isValidActiveForgottenUser(activeUser)));
    }
    catch {
        return false;
    }
};
/**
 * await login/badge with fallback to sssp.config.activeUser
 */
export const getCurrentSznUser = async () => {
    const { activeUser, badge } = getConfig();
    // default ->do not wait for badge (0ms timeout)
    let timeout = 0;
    if (badge) {
        if (activeUser) {
            // badge + activeUser -> wait for badge (max 1000ms)
            timeout = 1000;
        }
        else {
            // badge -> wait for badge (max 5000ms)
            timeout = USER_TIMEOUT;
        }
    }
    return (await waitForLogin(timeout)) || activeUser || {};
};
