import { dbg } from '../../utils/dbg';
import served from '../../impress/served';
import writeIframe from './writeIframe';
import writeCodeToPage from './writeCodeToPage';
import { hitAction } from '../../impress/testMetric';
import { syncId } from './syncId';
import { getConfig } from '../../config';
import { gamFallback } from '../../utils/gam';

/**
 * @module sssp/writeAd
 * @desc Module for rendering ads on a page using its method <code>writeAd</code>.
 */

const handleEmpty = (ad, data) => {
  dbg('no ad type:', ad.type);
  hitAction('writeAdEmpty', ad.zoneId);
  served(ad.tracking.served);

  // GAM fallback (optional)
  if (getConfig().fallbackToGAM) {
    gamFallback(ad, data);
  }
};

const handleIframe = (ad, data) => {
  const action = `writeAdIframe${ad.type === 'iframe_url' ? 'URL' : ''}`;
  hitAction(action, ad.zoneId);
  writeIframe(ad, data);
  served(ad.tracking.served);
};

const handleCode = (ad, data) => {
  hitAction('writeAdCode', ad.zoneId);
  writeCodeToPage(ad, data);
};

const handleIncorrectType = (ad) => ad.zoneId && hitAction('writeAdWrongFormat', ad.zoneId);

/**
 * Main ad rendering method.</br>
 * Public function <code>sssp.writeAd</code></br>
 * <a href="https://ssp.seznam.cz/static/doc/#odezvy-z-ssp">Public description</a>
 * (the public documentation describes some of the fields, not all)
 * @param {Object} ad Advertisement (banner).
 * @param {string} ad.data - Data of banner.
 * @param {string} [ad.content] - Content of banner (=ad.data?)
 * @param {string} ad.type - Type of advertisement (empty, iframe_url, iframe, code, json_url).
 * @param {boolean} [ad.iframe] - If ad should be rendered as <code>iframe</code>.<br>
 * <b>Warning:</b> Deprecated. Alternative is field <code>ad.type</code>.
 *
 * @param {string} ad.id - The identifier of the HTML element to add the ad to.
 * @param {string} ad.dsp - Advert DSP - Demand Side Platform ('SKLIK', 'ADVERT', 'OGAR', 'ONEGAR', 'APPNEXUS', 'PUBMATIC', PUBMATIC2').
 * @param {number} ad.height - Ad height in pixels.
 * @param {number} ad.width - Ad width in pixels.
 * @param {number} ad.zoneId - Unique ad zone identifier. <br/>
 * This number is assigned to the zone automatically when an ad code is generated in the <a href="https://partner.seznam.cz/seznam-ssp/">partner interface</a>
 * @param {string} ad.zoneName - Human readable name corresponding to zoneId (for easier debugging).

 * @param {string} ad.impressId - Impress ID.
 * @param {string} ad.requestId - Request ID.
 * @param {string} ad.cacheId - Cache ID.
 * @param {boolean} ad.responsive - Banner is responsive or not. </br>
 * @param {number} [ad.ttl] - Number of seconds between auction and impression.
 * @param {number} [ad.pawTimeoutId] - Timer ID of the event `<code>showPaw</code>
 *
 * @param {object} ad.tracking - Tracking URLs. Contains URLs of all measurement codes.
 * @param {string[]} ad.tracking.served - Array of impression and miss URLs.
 * @param {string[]} ad.tracking.visible - Array of visible URLs.
 *
 * @param {object} ad.bidIds - Additional ad identifiers.
 * @param {string} ad.bidIds.cid - Campaigns identifiers.
 * @param {string} [ad.bidIds.crid] - Creatives identifiers.
 * @param {number} [ad.bidIds.fid] - Ad Firmy.cz identifier.
 *
 * @param {object} ad.dbginfo - Debug information from DSP.
 * @param {string} [ad.dbginfo.requestId] - Request ID.
 * @param {string} [ad.dbginfo.bidId] - Additional ad identifier.
 * @param {string[]} ad.dbginfo.spotId - ID of a spot to be served.
 *
 *
 * @param {Object} data - Zone description.
 * @param {string} data.id - The identifier of the HTML element to add the ad to.
 * @param {number} [data.zoneId] - Unique ad zone identifier.
 * @param {number} [data.height] - Ad height in pixels.
 * @param {number} [data.width] - Ad width in pixels.
 * @param {object} [data.options] - Additional options
 * @param {boolean} [data.options.sspRendering] - Whether the ad is rendered by ssp script (true) or externally (false, null).
 */
const writeAd = (ad, data) => {
  dbg('info', '### starting write ads ###');

  if (!ad) {
    dbg('error', 'invalid "ad" object', ad);
    return;
  }

  dbg('with parameters', ad, data);

  switch (ad.type) {
    case 'empty':
      handleEmpty(ad, data);
      break;

    case 'iframe_url':
    case 'iframe':
      handleIframe(ad, data);
      break;

    case 'code':
      handleCode(ad, data);
      break;

    default:
      handleIncorrectType(ad);
      break;
  }
  syncId(ad, data);
};

export default writeAd;
