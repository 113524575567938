import { getConfig } from '#Modules/config';
import { getOverrides } from '#Modules/utils/getOverrides';
export const getAdserverUrl = ({ protocol, server, version, path, test }) => {
    const { redirect } = getOverrides();
    const host = redirect || server;
    return `${protocol}${host}/${version}${path}${test ? '?test=1' : ''}`;
};
export const omitWww = (hostname) => hostname.replace(/^www\./, '');
export const getImageBaseUrl = () => {
    const { protocol, staticServer } = getConfig();
    return `${protocol}${staticServer}/static/img`;
};
