import { getConfig } from '#Modules/config/index';
import { getSessionData } from '#Modules/session/sessionDetection';
import { allowedSources } from '#Modules/config/configValues';
import { isValidActiveUserInConfig } from '#Modules/utils/user';
/**
 * @desc Module containing supporting methods for configuration.
 */
/**
 * Table of allowed config properties and their types.
 * Simple type declarations will be evaluated using typeof,
 * more sophisticated value checks are implemented using a callback.
 */
const configTypes = {
    ab: 'object',
    adblock: 'boolean',
    badge: (value) => (typeof value === 'boolean' ? value : null),
    callCallbackOnError: 'boolean',
    cidsAutoSend: 'boolean',
    cookieDomain: 'string',
    debugExtensionId: 'string',
    fallbackToGAM: 'boolean',
    iframeStaticServer: 'string',
    isFollowerSsp: 'boolean',
    premium: (value) => (typeof value === 'boolean' ? value : null),
    preparePositionsCallback: 'function',
    protocol: (value) => (value === 'http://' || value === 'https://' ? value : null),
    requestErrorCallback: 'function',
    rusId: (value) => (typeof value === 'number' ? value : null),
    said: 'string',
    server: 'string',
    serviceIdForAllZones: 'string',
    site: 'string',
    source: (value) => getSessionData().media ||
        (Object.values(allowedSources).includes(value) ? value : null),
    staticServer: 'string',
    test: 'boolean',
    webId: 'number',
    activeUser: (value) => (isValidActiveUserInConfig(value) ? value : null),
};
/**
 * Gets default conf value
 */
const _getDefaultValue = (key) => {
    const config = getConfig();
    return config[key];
};
/**
 * Сhecks the correctness of the config value
 */
const _validateValue = (key, value) => {
    const configType = configTypes[key];
    if (!configType) {
        return null;
    }
    if (typeof configType === 'function') {
        return configType(value);
    }
    return typeof value === configType ? value : _getDefaultValue(key);
};
/**
 * Iterates through all the new config parameters and returns the main ssp config object
 */
const _reduceConfig = (config) => {
    return Object.entries(config).reduce((acc, current) => {
        const [key, value] = current;
        const validatedValue = _validateValue(key, value);
        if (validatedValue !== null) {
            acc[key] = validatedValue;
        }
        return acc;
    }, {});
};
/**
 * Prepare a configuration object that only includes permitted keys and ensure that each key's value is of the correct type
 * See permitted keys an values on @link https://ssp.seznam.cz/static/doc/nastaveni
 */
export const prepareConfig = (config) => {
    if (typeof config !== 'object' || Array.isArray(config) || config === null) {
        return null;
    }
    if (config.server === 'ssp.imedia.cz') {
        // want to use seznam domain instead of imedia
        config.server = getConfig().server;
    }
    return _reduceConfig(config);
};
