const SCALE_REGEX = /scale\(([0-9]+(?:\.[0-9]*)?)\)/;

/**
 * Returns whether the element has been resized by SSP
 * @param {HTMLElement} contResizing Element that is resized by SSP
 * @return {Boolean} Is element scaled?
 */
const isScaled = (contResizing) =>
  !!(
    contResizing &&
    contResizing.style &&
    contResizing.style.transform &&
    contResizing.style.transform.match(SCALE_REGEX)
  );

/**
 * If the container has been scaled by SSP, return its scale modifier as a float
 * @param {HTMLElement} contResizing Element that is resized by SSP
 * @return {float | 1} Scale modifer - 1 if not scaled
 */
const getScaleModifier = (contResizing) => {
  if (!isScaled(contResizing)) {
    // No scaling - scale modifier is 1
    return 1;
  }

  const transformString = contResizing.style.transform;
  const resultArr = transformString.match(SCALE_REGEX);

  if (!(resultArr && resultArr.length && resultArr.length === 2)) {
    // Unexpected result - return modifier 1
    return 1;
  }

  const result = parseFloat(resultArr[1]);

  if (isNaN(result)) {
    // Unexpected result - return modifier 1
    return 1;
  }

  // Successfully got modifier as a float - return it
  return result;
};

export { isScaled, getScaleModifier };
