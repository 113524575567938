import { dbg } from '../utils/dbg';
import hitError from './hitError';

/**
 * Zapocitava served (miss, impress, visible) hity
 * @param urlList (Array) - pole impress URL
 */
const served = (urlList) => {
  if (!Array.isArray(urlList)) {
    return; // neni served - video reklama
  }

  dbg('info', '### served hits ###');
  urlList.forEach((item) => {
    dbg('served URL:', item);

    var i = new Image(1, 1);
    if (item?.includes('i.seznam.cz')) {
      i.onerror = () => {
        dbg('error', 'Failed count served URL:', item);
        hitError(urlList);
      };
    }
    i.src = item;
  });
};

export default served;
