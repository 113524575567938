import { setElementAttributes } from '#Modules/utils/helpers/setElementAttributes';

/**
 * Vyrobi element iframu a nastavi mu rozmery podle banneru nebo 100%
 * @param ad {Object} - reklama z ad serveru
 * @return iframe {Node} - iframe element
 */
const createIframe = (ad) => {
  const iframe = document.createElement('iframe');

  setElementAttributes(iframe, {
    frameborder: 'no',
    scrolling: 'no',
    width: ad.width,
    height: ad.height,
    id: 'x' + Math.floor(Math.random() * 100000),
    style: `
			display: block;
      width: 100%;
			height: 100%;
		`,
  });

  return iframe;
};

export { createIframe };
