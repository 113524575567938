/* global PACKAGE_VERSION */

/** The env variable PACKAGE_VERSION needs to be wrapped in try-catch if undefined.
 * Otherwise, Cypress crashes when importing the value from config. */
const getPackageVersion = () => {
  try {
    return PACKAGE_VERSION;
  } catch (e) {
    return 'development';
  }
};

export default getPackageVersion;
