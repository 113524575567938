import { getConfig } from '#Modules/config';
import { dbg } from '#Modules/utils/dbg';
/**
 * Generic method for sending hits to ssp
 * @see https://sg1.glpages.seznam.net/ssp/ssp-server/doc/bussiness-logs/event-log.html
 */
export const sendHit = (urlParams) => {
    try {
        const { protocol, server } = getConfig();
        const hitURL = new URL(`${protocol}${server}/v2/event`);
        for (const param in urlParams) {
            hitURL.searchParams.append(param, urlParams[param]);
        }
        dbg('info', `### event hit to url ${hitURL} ###`);
        const i = new Image(1, 1);
        i.src = hitURL.href;
    }
    catch {
        dbg('error', `### event hit failed ###`);
    }
};
