import { INT_PAW_LINK } from '../../constants';

// CSS selector for paws
const CSS_PAWS = `div.packa > a[href="${INT_PAW_LINK}"]`;

// URLs
const urls = {
  1: 'https://partner.seznam.cz/app/ochrana-soukromi', // with our CMP; no our CMP, but cookie copied
  2: 'https://partner.seznam.cz/cmp-externi/', // no our CMP, no cookie copied
};

// Link
let link = null;
let cmpCanManageAds = null;

/**
 * Set the link
 * @param {boolean} canManageAds
 */
const setLink = (canManageAds) => {
  link = canManageAds ? urls[1] : urls[2];
  /* if (inCmp2Whitelist(window.location.hostname)) { TODO az bude CMP2 externi, tak  pridat version
		link += '?cmpversion=2';
	} */
};

/**
 * Modifies href attribute of the paw link
 * @param {*} pawLink node element
 */
const changeLink = (pawLink) => {
  if (pawLink && pawLink.href && link) {
    pawLink.href = link;
  }
};

/**
 * Returns correct url for paw in ad
 * @returns {String} URL for paws in ad
 */
const getExternalPawsUrl = () => {
  const url = cmpCanManageAds
    ? 'https://partner.seznam.cz/app/ochrana-soukromi'
    : 'https://partner.seznam.cz/cmp-externi/';
  return url;
};

/**
 * Starts observer for paw links
 */
const checkChangesInDOM = () => {
  if (typeof MutationObserver === 'undefined') {
    return;
  }

  const callback = function (mutationsList) {
    //observer
    for (const index in mutationsList) {
      const mutation = mutationsList[index];
      if (mutation.type === 'childList') {
        const nodes = Array.from(mutation.addedNodes);
        nodes.forEach((node) => {
          if (node?.nodeName.toLowerCase() === 'div' && node.className.indexOf('sssp-posCont') !== -1) {
            const pawLink = node.querySelector(CSS_PAWS);
            changeLink(pawLink);
          }
        });
      }
    }
  };
  const observer = new MutationObserver(callback);
  observer.observe(document.body, { attributes: false, childList: true, subtree: true });
};

/**
 * Set paw links in the page
 * @param {boolean} canManageAds
 */
const setPaws = (canManageAds) => {
  setLink(canManageAds);
  cmpCanManageAds = canManageAds;
  checkChangesInDOM();
};

export { getExternalPawsUrl, setPaws };
