import { safelyExecuteCallback } from '../utils/safeCallback';

const callPositionErrorCallback = (position, errorMessage) =>
  position.options &&
  position.options.infoErrorCallback &&
  typeof position.options.infoErrorCallback === 'function' &&
  /* All conditions above are true. Invoke the callback. */
  safelyExecuteCallback(() => {
    position.options.infoErrorCallback(position, errorMessage);
  });

export default callPositionErrorCallback;
