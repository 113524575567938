import { getConfig } from '../../config';
import { getCustoms } from '../../customs';
import { dbg } from '../../utils/dbg';
import { CONSENT } from '@sklik/cmp2-common';

var finished3rdparty = false;
/**
 * Sync 3rd party systémů a změření pageview
 */

function iframeLoaded(e) {
  var iframe = e.target;
  iframe.removeEventListener('load', iframeLoaded);
  setTimeout(() => {
    if (iframe && iframe.parentNode instanceof Element) {
      iframe.parentNode.removeChild(iframe);
    }
  }, 5000);
}

function createIframe(src) {
  var iframe = document.createElement('iframe');
  var container = document.body || document.head;
  iframe.frameBorder = 0;
  iframe.setAttribute('style', 'position:absolute; top:-1px;left:-1px;visibility:hidden; width: 1px; height: 1px;');
  iframe.src = src;
  iframe.addEventListener('load', iframeLoaded);
  container.appendChild(iframe);
}

var sync3rdparty = function () {
  const consent = CONSENT.getConsentFromCookieList();
  if (finished3rdparty || !consent?.purposeOne) {
    return;
  }
  dbg('info', '### starting 3rd party cookie sync ###');
  finished3rdparty = true;
  const { staticServer } = getConfig();

  /*synchronizace pubmaticu */
  createIframe(
    '//ads.pubmatic.com/AdServer/js/user_sync.html?p=49307&predirect=' +
      encodeURIComponent('//' + staticServer + '/static/html/sync/pub_sync.html?pmId=')
  );

  /*synchronizace apnexuxu */
  createIframe('//secure.adnxs.com/getuid?//' + staticServer + '/static/html/sync/apn_sync.html?uid=' + '$UID');

  /*synchronizce adformu*/
  const adformUrl = getCustoms('adform') || '//h.seznam.cz/html/adform_sync.html';
  createIframe(adformUrl);

  /*synchronizace Criteo*/
  let gdprPart = 'gdpr=0';
  if (consent?.consentString) {
    gdprPart = 'gdpr=1&gdpr_consent=' + consent.consentString;
  }
  createIframe(
    '//dis.criteo.com/dis/usersync.aspx?r=153&p=335&cp=seznam&cu=1&' +
      gdprPart +
      '&url=' +
      encodeURIComponent('https://' + staticServer + '/static/html/sync/criteo_sync.html?userid=@@CRITEO_USERID@@')
  );
};

export default sync3rdparty;
