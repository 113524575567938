import { getPawHTML, revivePaw } from './getPawHTML';
import { dbg } from '../utils/dbg';

/**
 * Appends pawHTML to an element
 * @param {Object} ad - reklama z sssp
 * @param {HTMLElement} element - HTML DOM element, where the paw should be inserted
 * @param {Boolean} visible - visibility of "paw"
 */
export const appendPawToElement = (ad, element, visible = true) => {
  const isValidInput = ad && ad.dsp && element && element.insertAdjacentHTML;

  if (!isValidInput) {
    dbg('error', 'invalid arguments provided to "appendPawToElement"', { ad, element });
    return;
  }

  const pawElement = getPawHTML(ad, visible);

  if (pawElement) {
    element.insertAdjacentHTML('beforeend', pawElement);
    revivePaw(ad, element, ['first', 'last']);
  }
};
