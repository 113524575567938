import { dbg } from '../utils/dbg';
import { getDot } from './dotLoader';

/**
 * Zalogovat pouzita zoneIds
 * @param data (Object) - popis pozic ve strance
 */
const logAds = (positions) => {
  const dot = getDot();
  const zones = positions.map((position) => position.zoneId).join(',');

  if (dot) {
    dbg('logging zones to Reporter', zones);
    dot.hit('ad', { d: { zones } });
  }
};

export default logAds;
