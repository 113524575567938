import { getRandomString } from '../utils/helpers/getRandomString';
import { extractId } from '../utils/helpers/extractId';
import { observePositions } from '../impress/visibilityMeasure';

let positionsStore = [];

let positionWatcher = null;
const runPositionsWatcher = () => positionWatcher && positionWatcher();

const getPositions = () => positionsStore;
const addPositions = (adObject) => {
  for (const addedAd of adObject) {
    if (addedAd.ad.zoneId) {
      const currentAdIndex = positionsStore.findIndex((position) => {
        return addedAd.ad.zoneId === position.ad.zoneId;
      });

      if (currentAdIndex !== -1) {
        positionsStore[currentAdIndex] = addedAd;
        continue;
      }
    }

    positionsStore.push(addedAd);
  }
};

/**
 * Update position container ID (useful for adding missing info in callback rendering use cases)
 */
const updatePositionId = (adObject) => {
  if (!adObject?.id || !adObject?.zoneId) {
    return;
  }
  positionsStore = positionsStore.map((p) =>
    p.ad.zoneId === adObject.zoneId && !p.ad.id
      ? { ...p, ad: { ...p.ad, id: adObject.id }, data: { ...p.data, id: adObject.id } }
      : p
  );

  // observe updated positions (for example newly added IDs)
  observePositions();
};

const getCompleteData = (positions, ads) => {
  if (positions.length !== ads.length) {
    return;
  }

  return positions.map((position, index) => ({
    data: position,
    ad: {
      impressId: getRandomString(25),
      ...ads[index],
    },
  }));
};

const getAdByImpressId = (impressId) => {
  return positionsStore.find((position) => {
    return impressId === position.ad.impressId;
  });
};

const setContainerElement = (ad, element) => {
  const adObject = getAdByImpressId(ad.impressId);

  if (adObject) {
    adObject.containerElement = element;
  }
};

/**
 * Get basic / trimmed info about positions (sorted by zoneId asc)
 */
const getBasicPositionsInfo = () =>
  getPositions()
    .sort((a, b) => a.ad.zoneId - b.ad.zoneId)
    .map((p) => {
      const { zoneId, type, width, height, responsive, zoneName, sbr } = p.ad;

      return {
        zoneId,
        zoneName,
        type,
        width,
        height,
        sbr: !!sbr,
        id: extractId(p.ad),
        responsive: !!responsive,
      };
    });

/**
 * Get positions (optinally register positions watcher and return function for unregister)
 */
const getRenderedAds = (watcher) => {
  const output = {};
  if (typeof watcher === 'function') {
    positionWatcher = () => {
      watcher(getBasicPositionsInfo());
      return true;
    };
    output.removeWatcher = () => (positionWatcher = null);
  }
  output.data = getBasicPositionsInfo();
  return output;
};

/**
 * Add or update position in positionsStore for visibility measuring
 * @param {Object} position - Position to add.
 * @param {string} position.id - Position's id.
 * @param {string} position.url - Visibility tracking URL.
 * @return {boolean} - True if anything was added, false otherwise.
 */

const updatePositionInStore = (position) => {
  if (!position?.id || !position?.url) {
    return false;
  }

  const { id, url } = position;

  const existingPosition = positionsStore.find((p) => p?.data?.id === id);

  if (existingPosition) {
    existingPosition.ad?.tracking?.visible.push(url);
  } else {
    positionsStore.push({
      data: {
        id,
      },
      ad: {
        id,
        zoneId: `${id}`,
        type: 'visibility-tracking',
        tracking: {
          visible: [url],
        },
      },
    });
  }

  return true;
};

/**
 * Add or update callback ad data in PositionsStore for visibility measuring
 * @param {Array<position>} - Positions to observe.
 */
const extendVisibilityTracking = (callbackAdData) => {
  if (!Array.isArray(callbackAdData)) {
    return;
  }

  // Try to add every position, returns true if any was added
  const changed = callbackAdData.map(updatePositionInStore).some(Boolean);

  if (changed) {
    // Only observe if anything has been added
    observePositions();
  }
};

export {
  getPositions,
  getCompleteData,
  addPositions,
  getAdByImpressId,
  setContainerElement,
  getRenderedAds,
  getBasicPositionsInfo,
  runPositionsWatcher,
  updatePositionId,
  extendVisibilityTracking,
};
