import { getUrlParams } from '#Modules/utils/helpers/getUrlParams';
/**
 * Looks for meta tags within the document.head that are set by the SSP debug extension.
 */
const _getExtensionOverrides = () => {
    const metaTags = Array.from(document.head.querySelectorAll('meta[name^="ssp:"]'));
    return metaTags.reduce((acc, current) => {
        acc[current.name.substring(4)] = current.content;
        return acc;
    }, {});
};
/**
 * Looks for URL parameters that are set by the SSP debug extension.
 */
const _getUrlOverrides = () => {
    const { sspredirect, sspstatic } = getUrlParams();
    return {
        ...(sspredirect && { redirect: sspredirect }),
        ...(sspstatic && { static: sspstatic }),
    };
};
export const getOverrides = () => ({
    ..._getExtensionOverrides(),
    ..._getUrlOverrides(),
});
