import { dbg } from '#Modules/utils/dbg';
import { getKeywords } from '#Modules/utils/keywords';
import { isTopWindow } from '#Modules/utils/topWindow';
import { getCurrentSznUser, USER_TIMEOUT } from '#Modules/utils/user';
import { getCookie } from '#Modules/utils/cookies';
import { parsedDspUrlParams } from '#Modules/ads/dspTest';
import { getIS } from './identityStorage';
const getDataEvent = 'SSSP_GET_DATA';
const sendDataEvent = 'SSSP_SEND_DATA';
const allowedFrames = ['localhost', 'www.stream.cz', 'stream.cz', 'podcasty.seznam.cz'];
const isValidVastDataMessagePayload = (data) => {
    try {
        return !!(data.satisfied && data.data !== undefined);
    }
    catch {
        return false;
    }
};
const _emptyObjectToNull = (data) => (Object.keys(data).length ? data : null);
const _getTagForZones = (zoneIds) => {
    const tags = getKeywords();
    const tagsForZones = tags.length
        ? zoneIds.reduce((acc, _, i) => ({ ...acc, [`tags-${i + 1}`]: tags }), {})
        : {};
    return tagsForZones;
};
// TODO: Update type for parsedUrlParams from dspTest.js when migrating to TypeScript
export const _getDspParamsForZones = (zoneIds, parsedUrlParams) => {
    const vastDspParams = parsedUrlParams.filter((param) => param.target === '1');
    const previewKeys = ['zoneId', 'adType', 'bannerid', 'crid', 'deliveryType', 'dsp', 'mediaType'];
    const previewData = zoneIds.reduce((acc, zone, index) => {
        const dspParam = vastDspParams.find((param) => param.zoneId.toString() === zone) || {};
        const dspPreviewData = previewKeys.reduce((accInner, key) => {
            if (dspParam.hasOwnProperty(key)) {
                accInner[`${key}-${index + 1}`] = dspParam[key].toString();
            }
            return accInner;
        }, {});
        return { ...acc, ...dspPreviewData };
    }, {});
    return previewData;
};
/**
 * Prepare final object -> do not include "empty" keys
 */
const _prepareDataObject = async (type, zoneIds, callback) => {
    const output = {};
    // user flags (with cookie fallback)
    const getUserFlags = (encodedFlags) => {
        const userFlags = encodedFlags || getCookie('user-flags');
        if (userFlags) {
            output.userFlags = userFlags;
        }
    };
    if (type === 'async') {
        /**
         * ASYNC data
         */
        // login (badge/config)
        const login = await getCurrentSznUser();
        const { sbr, uid, said, premium, encodedFlags } = login;
        const identity = getIS()?.getIdentities();
        const { consent, eid } = identity ?? {};
        // user flags string
        getUserFlags(encodedFlags);
        if (typeof sbr === 'boolean') {
            output.sbr = sbr;
        }
        if (uid) {
            output.rusId = uid;
        }
        if (said) {
            output.said = said;
        }
        if (typeof premium === 'boolean') {
            output.premium = premium;
        }
        if (consent) {
            output.consentString = consent.consentString;
        }
        if (eid) {
            output.eid = eid;
        }
    }
    else {
        /**
         * SYNC data
         */
        // keywords
        const tags = _getTagForZones(zoneIds);
        Object.assign(output, tags);
        // dsp ad preview params
        const dspParams = _getDspParamsForZones(zoneIds, parsedDspUrlParams());
        Object.assign(output, dspParams);
        // user flags string
        getUserFlags();
    }
    const payload = { data: _emptyObjectToNull(output), satisfied: type };
    // eslint-disable-next-line callback-return
    callback?.(payload);
    return payload;
};
/**
 * Get VAST related DATA (tags, ...) from anywhere -> top, embeds, etc.
 */
export const getVastData = (zoneIds = []) => {
    return new Promise(async (resolve) => {
        // shared resolver for top level / chlild frame / catch usecases
        const _resolver = (data, dbgData) => {
            dbg(...dbgData, data);
            resolve(data);
        };
        try {
            if (isTopWindow()) {
                //******************//
                // we are in TOP frame -> just read it from "itself"
                //******************//
                _resolver(_emptyObjectToNull({
                    ...(await _prepareDataObject('sync', zoneIds))?.data,
                    ...(await _prepareDataObject('async', zoneIds))?.data,
                }), ['info', 'Getting DATA as TOP window']);
            }
            else {
                //******************//
                // we are in CHILD frame -> request it from "top"
                //******************//
                let timeout = null;
                let interval = null;
                const needs = { sync: true, async: true };
                let dataBag = {};
                // handle response from TOP window
                const _fromTopHandler = (event) => {
                    const { name, payload } = event.data;
                    if (name === sendDataEvent && isValidVastDataMessagePayload(payload)) {
                        const { data, satisfied } = payload;
                        needs[satisfied] = false;
                        dataBag = { ...dataBag, ...data };
                        if (!needs.sync && !needs.async) {
                            window.clearTimeout(timeout);
                            window.clearInterval(interval);
                            window.removeEventListener('message', _fromTopHandler);
                            _resolver(_emptyObjectToNull(dataBag), ['info', 'Getting DATA as CHILD window']);
                        }
                    }
                };
                window.addEventListener('message', _fromTopHandler);
                // ask TOP window
                const pingTop = () => {
                    dbg('info', 'Sending DATA request to TOP window');
                    window.parent.postMessage({ name: getDataEvent, needs, zoneIds }, '*');
                };
                pingTop();
                // interval (we do not know when TOP instance will be ready)
                interval = window.setInterval(pingTop, 250);
                // timeout
                timeout = window.setTimeout(() => {
                    window.clearInterval(interval);
                    _resolver(_emptyObjectToNull(dataBag), [
                        'info',
                        `Getting DATA as CHILD window (timeout - ${USER_TIMEOUT}ms))`,
                    ]);
                }, USER_TIMEOUT);
            }
        }
        catch {
            _resolver(null, ['info', 'Getting DATA (fallbacked to NULL)']);
        }
    });
};
/**
 * Listen to data requests from child frames and send them back
 */
export const registerVastDataProvider = () => {
    if (isTopWindow()) {
        window.addEventListener('message', (event) => {
            try {
                const originHostname = new URL(event.origin).hostname;
                if (event.data.name === getDataEvent && allowedFrames.includes(originHostname) && event.data.zoneIds) {
                    const pingChild = (payload) => {
                        dbg('info', 'Sending DATA to children', payload);
                        event.source.postMessage({ name: sendDataEvent, payload }, event.origin);
                    };
                    if (event.data.needs.sync) {
                        _prepareDataObject('sync', event.data.zoneIds, pingChild);
                    }
                    if (event.data.needs.async) {
                        _prepareDataObject('async', event.data.zoneIds, pingChild);
                    }
                }
            }
            catch (e) {
                // noop
            }
        });
    }
};
