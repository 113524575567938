import { getSessionData, isFirstPageview } from './sessionDetection';
import { dopUrlParameters } from '../config/configValues';

/**
 * Get all available doporucovani AB test data from session.
 * @returns {Object}
 */
export const getDopAbTestValues = () => {
  const sessionData = getSessionData();
  return dopUrlParameters.reduce((acc, key) => {
    if (sessionData[key]) {
      acc[key] = sessionData[key];
    }
    return acc;
  }, {});
};

/**
 * Get doporucovani AB test data required for ad request payload
 * (request ID should be logged on first pageview only).
 * @returns {Object}
 */
export const getDopPayloadValues = () => {
  const abTestValues = getDopAbTestValues();
  if (!isFirstPageview()) {
    delete abTestValues.dop_req_id;
  }
  return abTestValues;
};
